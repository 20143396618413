import { gql } from 'apollo-boost'
import { deleteListQueryFromCache } from 'helper/cacheHelper'
import { NAMESPACES_QUERY } from '../Settings/gql'

const FULL_TESTSETSCRIPT_FRAGMENT = gql`
  fragment FullTestSetScript on TestSetScript {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    description
    script
    scriptType
    skip
    testSet {
      id
      name
    }
  }
`

const FULL_TESTSETEXCEL_FRAGMENT = gql`
  fragment FullTestSetExcel on TestSetExcel {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    filename
    skip
    testSet {
      id
      name
    }
  }
`

const FULL_EDITABLE_CONVO_FRAGMENT = gql`
  fragment FullEditableConvo on EditorConvo {
    scriptId
    name
    description
    skip
    partial
    begin {
      sender
      messageText
      not
      logicHooks { name args }
    }
    steps {
      sender
      channel
      messageText
      not
      optional
      asserters { name args not optional }
      logicHooks { name args }
      userInputs { name args }
      utteranceSamples
      utteranceCount
      sourceData
      partialConvos { id name }
    }
    end {
      sender
      messageText
      not
      asserters { name args not }
      logicHooks { name args }
    }
  }
`

export const TESTSETS_QUERY = gql`
  query TestSetsQuery {
    testsets {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      namespace
      description
      tags
      statsSkipCoach
      statsSkipCoachAdvanced
      statsUpdatedAt
      statsConvoCount
      statsPartialConvoCount
      statsUtterancesRefCount
      statsUtterancesCount
      statsScriptingMemoryCount
      statsWarnings { id name severity }
      dependencies { id name }
    }
  }
`

export const TESTSETS_DROPDOWN_QUERY = gql`
  query TestSetsQuery {
    testsets {
      id
      name
      namespace
      statsUpdatedAt
      statsConvoCount
      statsPartialConvoCount
      statsUtterancesRefCount
      statsUtterancesCount
      statsScriptingMemoryCount
    }
  }
`

export const TESTSETS_WITH_STATUS_PAGINATED_QUERY = gql`
  query TestSetsQuery($where: TestSetWhereInput, $orderBy: TestSetOrderByInput, $skip: Int, $first: Int) {
    testsets(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      namespace
      description
      tags
      statsSkipCoach
      statsSkipCoachAdvanced
      statsUpdatedAt
      statsConvoCount
      statsPartialConvoCount
      statsUtterancesRefCount
      statsUtterancesCount
      statsScriptingMemoryCount
      statsWarnings { id name severity }
      dependencies { id name }
      lastTestSession {
        id
        name
        chartData
        status
        jobs {
          id
          createdAt
          jobId
          progressPercent
          totalCount
          failedCount
          successCount
          status
          err
          started
          finished
        }
      }
      lastTestSessionDate
      lastCoachSessions {
        coachSession {
          id
          createdAt
          updatedAt
          embeddingsReady
          embeddingsStatus
          embeddingsErr
          kfoldReady
          kfoldErr
          kfoldPrecision
          kfoldRecall
          kfoldF1
          loocvReady
          loocvErr
          loocvScore
          highlightReady
          highlightErr
          humanificationReady
          humanificationErr
          humanificationPrecision
          humanificationRecall
          humanificationF1
        }
      }
    }
  }
`

export const TESTSETS_COUNT_QUERY = gql`
  query TestSetsCountQuery {
    testsetsCount
  }
`

export const TESTSETS_COUNT_PAGINATED_QUERY = gql`
  query TestSetsCountQuery($where: TestSetWhereInput) {
    testsetsCount(where: $where)
  }
`

export const FULL_TESTSET_FRAGMENT = gql`
  fragment FullTestSet on TestSet {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    description
    tags
    useMatchingMode
    useMatchingModeWer
    expandConvos
    expandConvosMode
    expandConvosModeRandomCount
    expandUtterancesToConvos
    exportUtterancesUseNameAsIntent
    expandUtterancesIncomprehension
    expandUtterancesNaming
    expandUtterancesNamingUtteranceMax
    useScriptingMemory
    useScriptingMemoryMatchingMode
    expandScriptingMemory
    normalizeText
    skipAssertErrors
    skipTestCasesPatterns
    excelHasConvos
    excelHasPartialConvos
    excelHasUtterances
    excelHasScriptingMemory
    excelWorksheetsConvos
    excelWorksheetsPartialConvos
    excelWorksheetsUtterances
    excelWorksheetsScriptingMemory
    excelStartRow
    excelStartCol
    excelHasHeader
    excelHasNameCol
    jsonCheckerPath
    jsonRootPath
    jsonUtteranceRefPath
    jsonUtterancesPath
    markdownMode
    mediaBaseUri
    mediaBaseDir
    mediaUsageType
    csvDelimiter
    csvUseHeader
    csvQuote
    csvEscape
    csvUtteranceStartrow
    csvUtteranceStartrowHeader
    csvUtteranceStopOnEmpty
    selectionType
    dependencies { id name }
    depending { id name }
    repositories  { id name }
    folders { id name }
    downloadlinks { id name }
    statsSkipCoach
    statsSkipCoachAdvanced
}
`

export const FULL_TESTSET_FRAGMENT_WITH_SCRIPTS = gql`
  fragment FullTestSetWithScripts on TestSet {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    description
    tags
    useMatchingMode
    expandConvos
    expandConvosMode
    expandConvosModeRandomCount
    expandUtterancesToConvos
    exportUtterancesUseNameAsIntent
    expandUtterancesIncomprehension
    expandUtterancesNaming
    expandUtterancesNamingUtteranceMax
    useScriptingMemory
    useScriptingMemoryMatchingMode
    expandScriptingMemory
    normalizeText
    skipAssertErrors
    skipTestCasesPatterns
    excelHasConvos
    excelHasPartialConvos
    excelHasUtterances
    excelHasScriptingMemory
    excelWorksheetsConvos
    excelWorksheetsPartialConvos
    excelWorksheetsUtterances
    excelWorksheetsScriptingMemory
    excelStartRow
    excelStartCol
    excelHasHeader
    excelHasNameCol
    jsonCheckerPath
    jsonRootPath
    jsonUtteranceRefPath
    jsonUtterancesPath
    markdownMode
    mediaBaseUri
    mediaBaseDir
    mediaUsageType
    csvDelimiter
    csvUseHeader
    csvQuote
    csvEscape
    csvUtteranceStartrow
    csvUtteranceStartrowHeader
    csvUtteranceStopOnEmpty
    selectionType
    dependencies { id name }
    repositories  { id name }
    folders { id name }
    downloadlinks { id name }
    scripts { id name scriptType }
    statsSkipCoach
    statsSkipCoachAdvanced
}
`

export const TESTSET_STATS_FRAGMENT = gql`
  fragment TestSetStats on TestSet {
    id
    name
    namespace
    statsSkipCoach
    statsSkipCoachAdvanced
    statsUpdatedAt
    statsConvoCount
    statsPartialConvoCount
    statsUtterancesRefCount
    statsUtterancesCount
    statsScriptingMemoryCount
    statsWarnings {
      id
      name
      description
      severity
      compiledConvo {
        id
        name
        script { id name scriptType }
        repository { id name }
        folder { id name }
        downloadlink { id name }
        excel { id name }
      }
      compiledUtterance {
        id
        name
        script { id name scriptType }
        repository { id name }
        folder { id name }
        downloadlink { id name }
        excel { id name }
      }
      compiledScriptingMemory {
        id
        name
        script { id name scriptType }
        repository { id name }
        folder { id name }
        downloadlink { id name }
        excel { id name }
      }
    }
    lastCoachSessions {
      coachSession {
        id
      }
    }
  }
`

export const TESTSET_QUERY = gql`
  query TestSetQuery($id: ID!) {
    testset(id: $id) {
      ...FullTestSet
    }
  }
  ${FULL_TESTSET_FRAGMENT}
`

export const TESTSET_STATS_QUERY = gql`
  query TestSetStatsQuery($id: ID!) {
    testset(id: $id) {
      ...TestSetStats
    }
  }
  ${TESTSET_STATS_FRAGMENT}
`

export const TESTSET_COMPILEDCONVOS_QUERY = gql`
  query TestSetCompiledConvosQuery($testSetId: ID!, $testSetScriptId: ID, $content: TestSetContentSelector, $skip: Int, $first: Int) {
    testsetcompiledconvos(testSetId: $testSetId, testSetScriptId: $testSetScriptId, content: $content, skip: $skip, first: $first) @connection(key: "testsetcompiledconvos", filter: ["testSetId", "testSetScriptId", "content"]) {
      id
      name
      order
      description
      sourceTag
      stepCount
      stepSamples
      partial
      script { id name scriptType skip }
      repository { id name }
      folder { id name }
      downloadlink { id name }
      excel { id name }
      warnings { id name description severity }
    }
    testset(id: $testSetId) {
      id
      statsConvoCount
      statsPartialConvoCount
    }
  }
`

export const TESTSET_COMPILEDUTTERANCES_QUERY = gql`
  query TestSetCompiledUtterancesQuery($testSetId: ID!, $testSetScriptId: ID, $content: TestSetContentSelector, $skip: Int, $first: Int) {
    testsetcompiledutterances(testSetId: $testSetId, testSetScriptId: $testSetScriptId, content: $content, skip: $skip, first: $first) @connection(key: "testsetcompiledutterances", filter: ["testSetId", "testSetScriptId", "content"]) {
      id
      name
      sourceTag
      uttCount
      uttSamples
      script { id name scriptType skip }
      repository { id name }
      folder { id name }
      downloadlink { id name }
      excel { id name }
      warnings { id name description severity }
    }
    testset(id: $testSetId) {
      id
      statsUtterancesRefCount
    }
  }
`

export const TESTSET_VALIDPARTIALCONVOS_QUERY = gql`
  query TestSetValidPartialConvosQuery($testSetId: ID!) {
    testsetvalidpartialconvos(testSetId: $testSetId) {
      id
      name
      stepCount
      testSet { id name }
      script { id name scriptType skip }
    }
  }
`

export const TESTSET_VALIDUTTERANCES_QUERY = gql`
  query TestSetValidUtterancesQuery($testSetId: ID!) {
    testsetvalidutterances(testSetId: $testSetId) {
      id
      name
      uttCount
      uttSamples
      testSet { id name }
      script { id name scriptType skip }
    }
  }
`

export const TESTSET_TREE_QUERY = gql`
  query TestSetTreeQuery($id: ID!) {
    testsettree(id: $id)
  }
`

export const CREATE_TESTSET = gql`
  mutation CreateTestSet($testSet: TestSetCreateInput!) {
    createTestSet(testSet: $testSet) {
      ...FullTestSetWithScripts
    }
  }
  ${FULL_TESTSET_FRAGMENT_WITH_SCRIPTS}
`

export const QUICKCREATE_TESTSET = gql`
  mutation QuickCreateTestSet($filename: String!, $filecontent: String!, $namespace: String) {
    quickCreateTestSet(filename: $filename, filecontent: $filecontent, namespace: $namespace) {
      ...FullTestSet
    }
  }
  ${FULL_TESTSET_FRAGMENT}
`

export const UPDATE_TESTSET = gql`
  mutation UpdateTestSet($id: ID!, $testSet: TestSetUpdateInput!) {
    updateTestSet(id: $id, testSet: $testSet) {
      ...FullTestSet
    }
  }
  ${FULL_TESTSET_FRAGMENT}
`

export const DELETE_TESTSET = gql`
  mutation DeleteTestSet($id: ID!) {
    deleteTestSet(id: $id)
  }
`

export const UPDATE_TESTSETSTATS = gql`
  mutation UpdateTestSetStats($id: ID!) {
    updateTestSetStats(id: $id) {
      ...FullTestSet
    }
  }
  ${FULL_TESTSET_FRAGMENT}
`

export const TESTSETSOURCES_SCRIPTS_QUERY = gql`
  query TestSetScriptsQuery($testSetId: ID!) {
    testsetscripts(testSetId: $testSetId, skip: 0, first: 10000) @connection(key: "testsetscripts", filter: ["testSetId"]) {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      description
      scriptType
      skip
    }
  }
`

export const TESTSETSOURCES_EXCELS_QUERY = gql`
  query TestSetScriptsQuery($testSetId: ID!) {
    testsetexcels(testSetId: $testSetId, skip: 0, first: 10000) @connection(key: "testsetexcels", filter: ["testSetId"]) {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      filename
    }

  }
`

export const TESTSETSOURCES_REMOTE_QUERY = gql`
  query TestSetSourcesRemoteQuery($testSetId: ID!) {
    testsetrepositories(testSetId: $testSetId, skip: 0, first: 10000) @connection(key: "testsetrepositories", filter: ["testSetId"]) {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      giturl
      gitbranch
      gitdir
      gituser
      globFilter
      skip
    }
    testsetfolders(testSetId: $testSetId, skip: 0, first: 10000) @connection(key: "testsetfolders", filter: ["testSetId"]) {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      path
      globFilter
      skip
    }
    testsetdownloadlinks(testSetId: $testSetId, skip: 0, first: 10000) @connection(key: "testsetdownloadlinks", filter: ["testSetId"]) {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      url
      headers
      skip
    }
  }
`

export const GITREPOSITORYBRANCHES_QUERY = gql`
  query GitRepositoryBranchesQuery(
    $testSetId: ID!
    $id: ID
    $giturl: String!
    $gituser: String
    $gitpassword: String
  ) {
    gitrepositorybranches(testSetId: $testSetId, id: $id giturl: $giturl, gituser: $gituser, gitpassword: $gitpassword) {
      remoteBranches
      err
    }
  }
`

export const TESTSET_TESTPROJECTS_QUERY = gql`
  query TestSetTestProjectsQuery($testSetId: ID!) {
    testprojects(where: { testSets_some: { id: $testSetId } }) {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      namespace
      tags
      chatbot {
        id
        name
        namespace
        avatar
        containermode
      }
      testSets {
        id
        name
        namespace
      }
      deviceSets {
        id
        name
      }
    }
  }
`

export const CLONETESTSETOPTIONS_QUERY = gql`
  query CloneTestSetOptionsQuery {
    clonetestsetoptions {
      humanifiers
      translationFrom { name value }
      translationTo  { name value }
      paraphrase
    }
  }
`

export const CLONE_MODIFY_TESTSET = gql`
  mutation CloneAndModifyTestSet($id: ID!, $options: CloneTestSetOptionsChoosen!) {
    cloneAndModifyTestSet(id: $id, options: $options) {
      id
      name
    }
  }
`

export const CLONE_TESTSET_REFERENCES = gql`
  mutation CloneTestSetReferences($id: ID!, $options: CloneTestSetReferencesOptionsChoosen!) {
    cloneTestSetReferences(id: $id, options: $options) {
      id
      name
    }
  }
`

export const SPLIT_TESTSET = gql`
  mutation SplitTestSet($id: ID!, $testSetNames: [String!]!, $percentages: [Int!]!, $output: CloneTestSetOptionsOutputType!, $content: TestSetContentSelector!) {
    splitTestSet(id: $id, testSetNames: $testSetNames, percentages: $percentages, output: $output, content: $content) {
      id
      name
    }
  }
`

export const EXPORT_TESTSET_TO_GIT = gql`
  mutation ExportTestSetToGit($id: ID!, $options: ExportTestSetToGitOptionsChoosen!) {
    exportTestSetToGit(id: $id, options: $options)
  }
`

export const EXPORT_TESTSET_TO_FOLDER = gql`
  mutation ExportTestSetToFolder($id: ID!, $options: ExportTestSetToFolderOptionsChoosen!) {
    exportTestSetToFolder(id: $id, options: $options)
  }
`

export const IMPORT_TESTSET_FROM_FILE = gql`
  mutation ImportTestSetFromFile($id: ID!, $overwrite: Boolean!, $filename: String!, $filecontent: String!) {
    importToTestSet(id: $id, overwrite: $overwrite, filename: $filename, filecontent: $filecontent)
  }
`
export const TESTSET_PARAPHRASES = gql`
  query TestSetParaphrasesQuery($name: String, $utterances: [String!]!) {
    testsetparaphrases(name: $name, utterances: $utterances)
  }
`

export const TESTSET_EDITABLE_QUERY = gql`
  query TestSetEditableConvosQuery($testSetId: ID!) {
    testseteditableconvos(testSetId: $testSetId, skip: 0, first: 10000) @connection(key: "testseteditableconvos", filter: ["testSetId"]) {
      id
      name
      partial
      stepCount
      script {
        id
        createdAt
        updatedAt
        createdBy { id name }
        updatedBy { id name }
        name
      }
      warnings { id name severity }
    }
    testseteditableutterances(testSetId: $testSetId, skip: 0, first: 10000) @connection(key: "testseteditableutterances", filter: ["testSetId"]) {
      id
      name
      uttCount
      script {
        id
        createdAt
        updatedAt
        createdBy { id name }
        updatedBy { id name }
        name
      }
      warnings { id name severity }
    }
  }
`

const FULL_EDITABLE_UTTERANCE_FRAGMENT = gql`
  fragment FullEditableUtterance on EditorUtterance {
    scriptId
    name
    skip
    utterances
  }
`

export const TESTSET_EDITABLE_UTTERANCE_QUERY = gql`
query TestSetEditableUtteranceQuery($testSetScriptId: ID!, $name: String!) {
  testseteditableutterance(testSetScriptId: $testSetScriptId, name: $name) {
    ...FullEditableUtterance
  }
  testseteditableutterancewarnings(testSetScriptId: $testSetScriptId, name: $name) {
    id
    name
    description
    severity
  }
}
${FULL_EDITABLE_UTTERANCE_FRAGMENT}
`

export const TESTSET_EDITABLE_UTTERANCE_UPSERT = gql`
  mutation UpsertEditableUtterance($testSetId: ID!, $testSetScriptId: ID, $name: String!, $utterance: UpsertUtterance!) {
    upsertEditableUtterance(testSetId: $testSetId, testSetScriptId: $testSetScriptId, name: $name, utterance: $utterance) {
      ...FullEditableUtterance
    }
  }
  ${FULL_EDITABLE_UTTERANCE_FRAGMENT}
`

export const TESTSET_EDITABLE_UTTERANCE_DELETE = gql`
  mutation DeleteEditableUtterance($testSetId: ID!, $testSetScriptId: ID, $name: String!) {
    deleteEditableUtterance(testSetId: $testSetId, testSetScriptId: $testSetScriptId, name: $name)
  }
`


export const TESTSET_EDITABLE_CONVO_QUERY = gql`
query TestSetEditableConvoQuery($testSetScriptId: ID!, $name: String!) {
  testseteditableconvo(testSetScriptId: $testSetScriptId, name: $name) {
    ...FullEditableConvo
  }
  testseteditableconvowarnings(testSetScriptId: $testSetScriptId, name: $name) {
    id
    name
    description
    severity
  }
}
${FULL_EDITABLE_CONVO_FRAGMENT}
`

export const TESTSET_EDITABLE_CONVO_UPSERT = gql`
  mutation UpsertEditableConvo($testSetId: ID!, $testSetScriptId: ID, $name: String!, $convo: UpsertConvo!) {
    upsertEditableConvo(testSetId: $testSetId, testSetScriptId: $testSetScriptId, name: $name, convo: $convo) {
      ...FullEditableConvo
    }
  }
  ${FULL_EDITABLE_CONVO_FRAGMENT}
`

export const TESTSET_EDITABLE_CONVO_DELETE = gql`
  mutation DeleteEditableConvo($testSetId: ID!, $testSetScriptId: ID, $name: String!) {
    deleteEditableConvo(testSetId: $testSetId, testSetScriptId: $testSetScriptId, name: $name)
  }
`

export const TESTSET_SCRIPT_BULK_DELETE = gql`
  mutation BulkDeleteScript($testSetId: ID!, $testSetScriptIds: [ID!]) {
    bulkDeleteScript(testSetId: $testSetId, testSetScriptIds: $testSetScriptIds)
  }
`

export const RUN_TESTSET_EDITABLECONVO = gql`
  query RunTestSetEditableConvoQuery($chatbotId: ID!, $testSetId: ID, $convo: UpsertConvo!) {
    runtestseteditableconvo(chatbotId: $chatbotId, testSetId: $testSetId, convo: $convo) {
      convo
      logs
      err
      attachments { name base64 mimeType }
    }
  }
`

export const TESTSETSCRIPT_QUERY = gql`
  query TestSetScriptQuery($id: ID!) {
    testsetscript(id: $id) {
      ...FullTestSetScript
    }
  }
  ${FULL_TESTSETSCRIPT_FRAGMENT}
`

export const TESTSETSCRIPTREFERENCES_QUERY = gql`
  query TestSetScriptReferencesQuery($testSetScriptId: ID!) {
    testsetscriptreferences(testSetScriptId: $testSetScriptId) {
      id
      name
      description
      scriptType
      testSet {
        id
        name
      }
    }
  }
`

export const VALIDATETESTSETSCRIPT_QUERY = gql`
  query ValidateTestSetScriptQuery(
    $testSetId: ID!
    $testSetScriptId: ID
    $name: String
    $script: String!
    $scriptType: TestSetScriptType!
  ) {
    validatetestsetscript(testSetId: $testSetId, testSetScriptId: $testSetScriptId, name: $name, script: $script, scriptType: $scriptType) {
      name
      description
      script
      scriptType
      err
    }
  }
`

export const CREATE_TESTSETSCRIPT = gql`
  mutation CreateTestSetScript($testSetScript: TestSetScriptCreateInput!) {
    createTestSetScript(testSetScript: $testSetScript) {
      ...FullTestSetScript
    }
  }
  ${FULL_TESTSETSCRIPT_FRAGMENT}
`

export const UPDATE_TESTSETSCRIPT = gql`
  mutation UpdateTestSetScript(
    $id: ID!
    $testSetScript: TestSetScriptUpdateInput!
  ) {
    updateTestSetScript(id: $id, testSetScript: $testSetScript) {
      ...FullTestSetScript
    }
  }
  ${FULL_TESTSETSCRIPT_FRAGMENT}
`

export const CLONE_TESTSETSCRIPT = gql`
  mutation CloneTestSetScript($id: ID!, $name: String!) {
    cloneTestSetScript(id: $id, name: $name) {
      ...FullTestSetScript
    }
  }
  ${FULL_TESTSETSCRIPT_FRAGMENT}
`

export const BULK_CLONE_TESTSETSCRIPT = gql`
  mutation BulkCloneTestSetScript($ids: [ID!], $prefix: String!) {
    bulkCloneTestSetScript(ids: $ids, prefix: $prefix)
  }
`

export const COPY_TESTSETSCRIPT = gql`
  mutation CopyTestSetScript($id: ID!, $testSetId: ID!, $move: Boolean, $copyUtterances: Boolean, $copyPartialConvos: Boolean) {
    copyTestSetScript(id: $id, testSetId: $testSetId, move: $move copyUtterances: $copyUtterances, copyPartialConvos: $copyPartialConvos) {
      ...FullTestSetScript
    }
  }
  ${FULL_TESTSETSCRIPT_FRAGMENT}
`

export const BULK_COPY_TESTSETSCRIPT = gql`
  mutation BulkCopyTestSetScript($ids: [ID!], $testSetId: ID!, $move: Boolean) {
    bulkCopyTestSetScript(ids: $ids, testSetId: $testSetId, move: $move) {
      testSetId
    }
  }
`


export const DELETE_TESTSETSCRIPT = gql`
  mutation DeleteTestSetScript($id: ID!) {
    deleteTestSetScript(id: $id)
  }
`

export const RUN_TESTSETSCRIPT = gql`
  query RunTestSetScriptQuery($chatbotId: ID!, $testSetId: ID, $script: String!, $scriptType: TestSetScriptType!, $scriptName: String) {
    runtestsetscript(chatbotId: $chatbotId, testSetId: $testSetId, script: $script, scriptType: $scriptType, scriptName: $scriptName) {
      convo
      logs
      err
      attachments { name base64 mimeType }
    }
  }
`

export const DELETE_TESTSETSCRIPTS = gql`
  mutation DeleteTestSetScripts($testSetId: ID!) {
    deleteTestSetScripts(testSetId: $testSetId)
  }
`

export const TESTSETEXCEL_QUERY = gql`
  query TestSetExcelQuery($id: ID!) {
    testsetexcel(id: $id) {
      ...FullTestSetExcel
    }
  }
  ${FULL_TESTSETEXCEL_FRAGMENT}
`

export const CREATE_TESTSETEXCEL = gql`
  mutation CreateTestSetExcel($testSetExcel: TestSetExcelCreateInput!) {
    createTestSetExcel(testSetExcel: $testSetExcel) {
      ...FullTestSetExcel
    }
  }
  ${FULL_TESTSETEXCEL_FRAGMENT}
`

export const UPDATE_TESTSETEXCEL = gql`
  mutation UpdateTestSetExcel(
    $id: ID!
    $testSetExcel: TestSetExcelUpdateInput!
  ) {
    updateTestSetExcel(id: $id, testSetExcel: $testSetExcel) {
      ...FullTestSetExcel
    }
  }
  ${FULL_TESTSETEXCEL_FRAGMENT}
`

export const COPY_TESTSETEXCEL = gql`
  mutation CopyTestSetExcel($id: ID!, $testSetId: ID!) {
    copyTestSetExcel(id: $id, testSetId: $testSetId) {
      ...FullTestSetExcel
    }
  }
  ${FULL_TESTSETEXCEL_FRAGMENT}
`

export const DELETE_TESTSETEXCEL = gql`
  mutation DeleteTestSetExcel($id: ID!) {
    deleteTestSetExcel(id: $id)
  }
`

const FULL_TESTSETCOACHSESSION_FRAGMENT = gql`
  fragment FullTestSetCoachSession on TestSetCoachSession {
    id
    createdAt
    updatedAt
    embeddingsReady
    embeddingsStatus
    embeddingsErr
    chi2Ready
    chi2Status
    chi2Err
    kfoldReady
    kfoldErr
    kfoldPrecision
    kfoldRecall
    kfoldF1
    loocvReady
    loocvErr
    loocvScore
    highlightReady
    highlightErr
    humanificationReady
    humanificationErr
    humanificationPrecision
    humanificationRecall
    humanificationF1
    testSet {
      id
      name
      statsSkipCoach
      statsSkipCoachAdvanced
    }
  }
`

export const TESTSETCOACHSESSIONS_QUERY = gql`
  query TestSetCoachSessionsQuery($testSetId: ID!, $skip: Int, $first: Int) {
    testsetcoachsessions(testSetId: $testSetId, skip: $skip, first: $first) @connection(key: "testsetcoachsessions", filter: ["testSetId"]) {
      ...FullTestSetCoachSession
    }
  }
  ${FULL_TESTSETCOACHSESSION_FRAGMENT}
`

export const TESTSETFIRSTCOACHSESSION_QUERY = gql`
  query TestSetFirstCoachSessionQuery($testSetId: ID!) {
    testsetcoachsessions(testSetId: $testSetId, skip: 0, first: 1) @connection(key: "testsetfirstcoachsession", filter: ["testSetId"]) {
      ...FullTestSetCoachSession
    }
  }
  ${FULL_TESTSETCOACHSESSION_FRAGMENT}
`

export const TESTSETCOACHSESSION_QUERY = gql`
  query TestSetCoachSessionQuery($id: ID!) {
    testsetcoachsession(id: $id) {
      ...FullTestSetCoachSession
    }
  }
  ${FULL_TESTSETCOACHSESSION_FRAGMENT}
`

export const TESTSETCOACHSESSION_SUBSCRIPTION = gql`
  subscription TestSetCoachSessionSubscription($id: ID!) {
    coachSessionProgress(coachSessionId: $id) {
      ...FullTestSetCoachSession
    }
  }
  ${FULL_TESTSETCOACHSESSION_FRAGMENT}
`

export const TESTSETCOACHSESSIONSECTION_QUERY = gql`
  query TestSetCoachSessionSectionQuery($id: ID!, $section: String!) {
    testsetcoachsessionsection(id: $id, section: $section)
  }
`

export const TESTSETCOACHSESSIONSECTION_SUBSCRIPTION = gql`
  subscription TestSetCoachSessionSectionSubscription($id: ID!, $section: String!) {
    coachSessionSectionProgress(coachSessionId: $id, section: $section)
  }
`

export const CREATE_TESTSETCOACHSESSION = gql`
  mutation CreateTestSetCoachSession($testSetId: ID!) {
    createTestSetCoachSession(testSetCoachSession: { testSet: { connect: { id: $testSetId } } }) {
      ...FullTestSetCoachSession
    }
  }
  ${FULL_TESTSETCOACHSESSION_FRAGMENT}
`

export const DELETE_TESTSETCOACHSESSION = gql`
  mutation DeleteTestSetCoachSession($id: ID!) {
    deleteTestSetCoachSession(id: $id)
  }
`

export const VALIDATEFILE_GETSTATS_QUERY = gql`
  query ValidateFileGetStatsQuery(
    $testSetId: ID!
    $filename: String!
    $filecontent: String!
  ) {
    validatefilegetstats(testSetId: $testSetId, filename: $filename, filecontent: $filecontent) {
      convoCount
      convoNames
      partialConvoCount
      partialConvoNames
      uttCount
      uttNames
      scriptingMemoryCount
      scriptingMemoryNames
      err
    }
  }
`

export const SPEECH_SUPPORTED_VOICES_QUERY = gql`
  query SpeechSupportedVoicesQuery($id: ID, $profile: SpeechSynthesisProfileInput) {
    speechSupportedVoices(id: $id, profile: $profile) {
      name
      language
      gender
    }
  }
`

export const SPEECH_EFFECTS_QUERY = gql`
  query SpeechEffectsQuery($id: ID, $profile: SpeechNoiseProfileInput) {
    speechEffects(id: $id, profile: $profile) {
      name
      description
    }
  }
`

export const SPEECH_TTS_LANGUAGES_QUERY = gql`
  query SpeechTTSLanguagesQuery($id: ID, $profile: SpeechSynthesisProfileInput) {
    speechTTSLanguages(id: $id, profile: $profile)
  }
`

export const SPEECH_STT_LANGUAGES_QUERY = gql`
  query SpeechSTTLanguagesQuery($id: ID, $profile: SpeechRecognitionProfileInput) {
    speechSTTLanguages(id: $id, profile: $profile)
  }
`

export const SPEECH_JOBS = gql`
  query SpeechJobs($jobType: SpeechJobType, $testSetId: ID, $ids: [Int!]) {
    speechJobs(jobType: $jobType, testSetId: $testSetId, ids: $ids) @connection(key: "speechJobs", filter: ["jobType", "testSetId", "ids"]) {
      id
      createdAt
      jobType
      status
      title
      data
      outputFile
      err
    }
  }
`

export const SPEECH_JOBS_SUBSCRIPTION = gql`
  subscription SpeechJobs($jobType: SpeechJobType, $testSetId: ID, $ids: [Int!]) {
    speechJobs(jobType: $jobType, testSetId: $testSetId, ids: $ids) @connection(key: "speechJobs", filter: ["jobType", "testSetId", "ids"]) {
      id
      createdAt
      jobType
      status
      title
      data
      outputFile
      err
    }
  }
`

export const SPEECH_JOB = gql`
  query SpeechJobs($id: Int!) {
    speechJob(id: $id) {
      id
      testSetId
      createdAt
      jobType
      status
      title
      data
      outputFile
      err
    }
  }
`

export const SPEECH_JOB_SUBSCRIPTION = gql`
  subscription SpeechJobSubscription($id: Int!) {
    speechJobProgress(id: $id) {
      id
      testSetId
      createdAt
      jobType
      status
      title
      data
      outputFile
      err
    }
  }
`

export const SPEECH_START_TTS_JOB = gql`
  mutation SpeechStartTTSJob($job: SpeechStartTTSJob!) {
    speechStartTTSJob(job: $job)
  }
`

export const SPEECH_START_EFFECT_JOB = gql`
  mutation SpeechStartEffectJob($job: SpeechStartEffectJob!) {
    speechStartEffectJob(job: $job)
  }
`

export const SPEECH_APPLY_ON_CONVO = gql`
  mutation SpeechApplyOnConvo($input: SpeechApplyOnConvoInput!) {
    speechApplyOnConvo(input: $input) {
      testSetId
      testSetScriptId
      name
      jobIds
    }
  }
`

export const SPEECH_APPLY_ON_UTTERANCE = gql`
  mutation SpeechApplyOnUtterance($input: SpeechApplyOnUtteranceInput!) {
    speechApplyOnUtterance(input: $input) {
      testSetId
      mediaDir
      jobIds
    }
  }
`

export const CLONE_AND_MODIFY_TESTSET_HUMANIFIABLE_UTTERANCE_COUNT_QUERY = gql`
  query CloneAndModifyTestSetHumanifiableUtteranceCountQuery($testSetId: ID!) {
    cloneAndModifyTestSetHumanifiableUtteranceCount(testSetId: $testSetId)
  }
`

export const DeleteTestSetScriptsFromCache = (store, testSetScriptIds) => {
  const rootData = store.data.data
  Object.keys(rootData).forEach(key => {
    for (const testSetScriptId of testSetScriptIds) {
      if (key.indexOf(testSetScriptId) >= 0) {
        delete store.data.data[key]
      }
    }
  })
}

export const DeleteTestSetsListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^testsets/)
}

export const RefetchTestSetQueriesOnNewTestSession = (testSetId) => [

]

export const RefetchTestSetQueries = (testSetId, license) => [
  {
    query: NAMESPACES_QUERY
  },
  {
    query: TESTSETS_QUERY,
  },
  {
    query: TESTSETS_DROPDOWN_QUERY,
  },
  {
    query: TESTSETS_COUNT_QUERY,
  },
  ...(testSetId ? [
  {
    query: TESTSET_QUERY,
    variables: { id: testSetId },
  },
  {
    query: TESTSET_STATS_QUERY,
    variables: { id: testSetId },
  },
  {
    query: TESTSET_COMPILEDCONVOS_QUERY,
    variables: { testSetId },
  },
  {
    query: TESTSET_COMPILEDUTTERANCES_QUERY,
    variables: { testSetId },
  },
  {
    query: TESTSET_VALIDPARTIALCONVOS_QUERY,
    variables: { testSetId },
  },
  {
    query: TESTSET_VALIDUTTERANCES_QUERY,
    variables: { testSetId },
  },
  {
    query: TESTSET_TREE_QUERY,
    variables: { id: testSetId },
  },
  {
    query: TESTSETSOURCES_SCRIPTS_QUERY,
    variables: { testSetId },
  },
  {
    query: TESTSETCOACHSESSIONS_QUERY,
    variables: { testSetId },
  },
  {
    query: TESTSETFIRSTCOACHSESSION_QUERY,
    variables: { testSetId },
  },
  license && license.excel && {
    query: TESTSETSOURCES_EXCELS_QUERY,
    variables: { testSetId },
  },
  license && license.gitIntegration && {
    query: TESTSETSOURCES_REMOTE_QUERY,
    variables: { testSetId },
  },
  {
    query: TESTSET_EDITABLE_QUERY,
    variables: { testSetId },
  },
  {
    query: TESTSET_TESTPROJECTS_QUERY,
    variables: { testSetId },
  }] : [])
].filter(s => s)
