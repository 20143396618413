import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {Query, withApollo} from 'react-apollo'
// @material-ui/core components
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import withStyles from '@material-ui/core/styles/withStyles'
import AddIcon from '@material-ui/icons/Add'
import List from '@material-ui/core/List'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemAvatar from 'components/List/ListItem/ListItemAvatar'
import ListItemText from 'components/List/ListItem/ListItemText'
import Field from 'components/Form/OptionalField'
import { FieldArray } from 'react-final-form-arrays'
// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { renderTextField, required, composeValidators } from 'components/Form/Form'
import ShowIcon from 'components/Icon/ShowIcon'
import Chip from 'components/Chip/Chip'
import Button from 'components/Button/Button'
import ComponentChip from 'components/Convo/ComponentChip.jsx'
import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import { setAlertSuccessMessage } from 'actions/alert'
import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle'
import { renderUtteranceText } from './Shared'
import Text from 'components/Typography/Text'
import classNames from 'classnames'
import DropdownButton from 'components/Button/DropdownButton'
import SwitchButton from 'components/Button/SwitchButton'
import {Form, FormSpy} from 'react-final-form'
import { validateConvoNameUnique } from 'views/TestSets/validators'
import _ from 'lodash'
import Tooltip from 'components/Tooltip/Tooltip'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ErrorFormat from 'components/Info/ErrorFormat'
import arrayMutators from 'final-form-arrays'

export const MATCHING_MODE_DEFAULT = 'MATCHING_MODE_DEFAULT'

class ConvoEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      partialExpanded: {},
    }
  }

  onDragEnd(result, fields) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    fields.move(result.source.index, result.destination.index)
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  isConditionalStep (msg) {
    if(!msg.logicHooks) return false
    return  msg.logicHooks.some(lh => lh.name.startsWith('CONDITIONAL_STEP'))
  }

  isConditionalStepEndGroup (msg, nextMsg) {
    if(!nextMsg || nextMsg.sender !== 'bot' || !nextMsg.logicHooks || !nextMsg.logicHooks.some(lh => lh.name.toUpperCase().startsWith('CONDITIONAL_STEP'))) return true

    const conditionalLogicHook = msg.logicHooks.find(lh => lh.name.startsWith('CONDITIONAL_STEP'))
    const nextConditionalLogicHook = nextMsg.logicHooks.find(lh => lh.name.startsWith('CONDITIONAL_STEP'))
    return conditionalLogicHook.args[1] !== nextConditionalLogicHook.args[1]
  }

  openEditStepEditor = (msg, name, stepIndex, asserterExpanded) => !this.props.disabled && this.props.setParentState && this.props.setParentState({ editStep: msg, editStepName: name, editStepIndex: stepIndex, asserterExpanded })

  render() {
    const { fieldPrefix, values, change, disabled, hideName, warnings, setParentState, setTestSetState, classes, testSetId, testSetScriptId, history, setAlertSuccessMessage, queryEditableConvo, dbConvoToForm } = this.props
    const aggregatedWarnings = []
    let conditionalStepStarted = false
    for (const warning of (warnings || [])) {
      const foundWarn = aggregatedWarnings.find(aggWarn => warning.name === aggWarn.name)
      if (foundWarn) {
        foundWarn.description.push(<li key={warning.description}>{warning.description}</li>)
      } else {
        const aggregatedWarning = _.cloneDeep(warning)
        aggregatedWarning.description = [<li key={aggregatedWarning.description}>{aggregatedWarning.description}</li>]
        aggregatedWarnings.push(aggregatedWarning)
      }
    }

    return <GridContainer>
      {!hideName &&
        <GridItem xs={12} borderBottom header >
          <FormSpy subscription={{ values: true, form: true }} render={({ values, form }) => (
            <GridContainer autoHeight>
              <GridItem md={12} lg={8} classes={{ root: classes.testsetsUtteranceedit }}>
                <Tooltip title={values.name || ''}>
                  <Field
                    name="name"
                    component={renderTextField}
                    validate={composeValidators(required, async (value) => value ? validateConvoNameUnique(this.props.client, testSetId, testSetScriptId, value) : null)}
                    disableBorder
                    slimError
                    inlineEdit
                    //disabled={!this.hasWritePermission()}
                    data-unique="txtTestSetConvoName"
                  />
                 </Tooltip>
              </GridItem>
              <GridItem md={12} lg={4} right smallMarginTop className={classes.switchButtonCol} >
                <>
                  {!values.partial && testSetScriptId && <SwitchButton
                    leftLabel="Source Editor"
                    leftDataUnique="btnSourceEditor"
                    rightLabel="Convo Editor"
                    rightDataUnique="btnConvoEditor"
                    checked={'right'}
                    onLeftClick={() => {
                      if (testSetScriptId) {
                        history.push(`/testsets/view/${testSetId}/testcases/viewscript/${testSetScriptId}`)
                      } else if (values.partial) {
                        history.push(`/testsets/view/${testSetId}/testcases/registerscript/pconvo`)
                      } else {
                        history.push(`/testsets/view/${testSetId}/testcases/registerscript/convo`)
                      }
                      setTestSetState({ testCaseViewMode: 'source' })
                    }}
                    onCenterClick={() => {
                      history.push(`/testsets/view/${testSetId}/testcases/viewconvo/${testSetScriptId}/${encodeURIComponent(values.name)}`)
                    }}
                  />}

                  {(values.partial || !testSetScriptId) && <SwitchButton leftLabel="Source Editor" rightLabel="Convo Editor" leftDataUnique="btnSourceEditor" rightDataUnique="btnConvoEditor" checked="right" onLeftClick={() => {
                    if (testSetScriptId) {
                      history.push(`/testsets/view/${testSetId}/testcases/viewscript/${testSetScriptId}`)
                    } else if (values.partial) {
                      history.push(`/testsets/view/${testSetId}/testcases/registerscript/pconvo`)
                    } else {
                      history.push(`/testsets/view/${testSetId}/testcases/registerscript/convo`)
                    }
                    setTestSetState({ testCaseViewMode: 'source' })
                  }} />}
                </>
              </GridItem>
            </GridContainer>)}
          />
        </GridItem>
      }
      <GridItem xs={12}>
        <GridContainer padding>
          <GridItem xs={12}>
            {aggregatedWarnings && aggregatedWarnings.length > 0 && <GridContainer>
              {aggregatedWarnings.map((w, wi) => <GridItem xs={12} key={wi}>
                {w.severity === 'ERROR' && <Chip variant="error" label={w.name} tooltip={w.description} />}
                {w.severity === 'WARNING' && <Chip variant="warning" label={w.name} tooltip={w.description} />}
              </GridItem>)}
            </GridContainer>}
          </GridItem>
        </GridContainer>
      </GridItem>
      <FieldArray name={fieldPrefix ? `${fieldPrefix}.steps` : 'steps'}>
        {({ fields }) => {

          const moreItems = [
            {
              id: 'add_partial_convo',
              icon: 'plus',
              name: 'Partial Convo',
              onClick: () => {
                const msg = { sender: 'include', messageText: '' }
                fields.push(msg)
              }
            }
          ]
          if (!values.partial && !values.begin) {
            moreItems.push({
              id: 'add_begin_step',
              icon: 'plus',
              name: 'Begin Step',
              onClick: () => change('begin', { sender: 'begin' })
            })
          }
          if (!values.partial && !values.end) {
            moreItems.push({
              id: 'add_end_step',
              icon: 'plus',
              name: 'End Step',
              onClick: () => change('end', { sender: 'end' })
            })
          }
          return <React.Fragment>
            <GridItem xs={12} classes={{ root: classes.testsetsUtterancegridbg }} >
              <List component="div">
                {values.begin && <ListItem key="begin">
                  <Field name="begin">{() => null}</Field>
                  <ListItemAvatar>
                    <ShowIcon custom icon="beginStep" />
                  </ListItemAvatar>
                  <ListItemText
                    tabIndex={0}
                    className={classes.senderNone}
                    data-unique="liTestSetConvoEditorBegin"
                  >
                    {values.begin.logicHooks && values.begin.logicHooks.map((a, ai) =>
                      <ComponentChip componentType="logichook" component={a} key={`logichook_${ai}`} onClick={() => {
                        this.openEditStepEditor(values.begin, 'begin', 0, `LH-${ai}`)
                      }}/>)}
                    {!disabled && <>
                      <Tooltip title="Add New & Edit" placement="bottom">
                        <Button dashedSecondary justIcon noMargin aria-label="Add New & Edit" data-unique="btnTestSetConvoEditorBeginAdd" key={'beginEdit'} onClick={() => !disabled && setParentState && setParentState({ editStep: values.begin, editStepName: 'begin' })} >
                          <ShowIcon icon="plus" />
                        </Button>
                      </Tooltip>
                    </>}
                  </ListItemText>
                  {!disabled &&
                    <DropdownButton
                      aria-label="Actions"
                      tooltipTitle="Actions"
                      dots
                      items={[
                        {
                          id: 'pen',
                          icon: 'pen',
                          name: 'Edit',
                          onClick: () => setParentState && setParentState({ editStep: values.begin, editStepName: 'begin' }),
                          dataUnique: 'btnTestSetConvoEditorBeginCode'
                        },
                        {
                          id: 'trash',
                          icon: 'trash',
                          name: 'Trash',
                          onClick: () => change('begin', null),
                          dataUnique: 'btnTestSetConvoEditorBeginRemove'
                        }
                      ]}
                    ></DropdownButton>
                  }
                </ListItem>}

                <DragDropContext onDragEnd={(results) => this.onDragEnd(results, fields)}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {fields.map((name, stepIndex) => {
                          const msg = values.steps[stepIndex]
                          if (!msg) return null
                          let renderUtteranceOnClick
                          renderUtteranceOnClick = () => {
                            this.openEditStepEditor(msg, name, stepIndex)
                          }

                          let conditionalStepPrefixComponent
                          if (msg.sender === 'bot' && this.isConditionalStep(msg)) {
                            if (!this.isConditionalStepEndGroup(msg, values.steps[stepIndex + 1])) {
                              if (conditionalStepStarted) {
                                conditionalStepPrefixComponent = <ShowIcon custom xs svgWhiteDark icon="conditionalLine"/>
                              } else {
                                conditionalStepPrefixComponent = <ShowIcon custom svgWhiteDark xs icon="conditionalStart" className={classes.svgColor}/>
                                conditionalStepStarted = true
                              }
                            } else {
                              if (conditionalStepStarted) {
                                conditionalStepPrefixComponent = <ShowIcon custom xs svgWhiteDark icon="conditionalEnd"/>
                                conditionalStepStarted = false
                              } else {
                                conditionalStepPrefixComponent = <ShowIcon custom xs svgWhiteDark icon="conditionalStartend"/>
                              }
                            }
                          }
                          return (
                            <Draggable key={name} draggableId={name} index={stepIndex}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ListItem key={name}  >

                                    <Field name={`${name}.messageText`}>{() => null}</Field>
                                    <Field name={`${name}.not`}>{() => null}</Field>
                                    <Field name={`${name}.userInputs`}>{() => null}</Field>
                                    <Field name={`${name}.asserters`}>{() => null}</Field>

                                    {msg.sender === 'bot' && (
                                      <div className={classes.senderBotIcon}>
                                        <ListItemIcon>
                                          <ShowIcon custom icon="senderBot" />
                                        </ListItemIcon>
                                      </div>
                                    )}
                                    {conditionalStepPrefixComponent}
                                    <ListItemText
                                      tabIndex={0}
                                      data-unique={`liTestSetConvoEditorStep_${stepIndex}`}
                                      className={classNames({
                                        [classes.senderMe]: msg.sender === 'me',
                                        [classes.senderBot]: msg.sender === 'bot',
                                        [classes.senderInclude]: msg.sender === 'include',
                                        [classes.senderNone]: msg.sender !== 'me' && msg.sender !== 'bot' && msg.sender !== 'include',
                                        [classes.senderOptional]: msg.optional
                                      })}
                                    >
                                      {msg.sender === 'include' && queryEditableConvo && msg.partialConvos && msg.partialConvos.map((pConvo, ai) => {
                                        return <GridContainer key={`include_${ai}`} >
                                          <GridItem xs={12}>
                                            <ExpansionPanel style={{marginBottom: 5}} data-unique={`expConvoEditor_${ai}`} key={`include_${ai}`} expanded={this.state.partialExpanded[pConvo.id]} onChange={() => this.setState({ partialExpanded: {[pConvo.id]: !this.state.partialExpanded[pConvo.id]} })}>
                                              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <ShowIcon custom icon="partialConvo" /> <Text topMarginxs leftMarginxs regular>{pConvo.name}</Text>
                                              </ExpansionPanelSummary>
                                              <ExpansionPanelDetails>
                                                <GridContainer nounset>
                                                  <GridItem xs={12}>
                                                    <Query
                                                      query={queryEditableConvo}
                                                      variables={{ testSetScriptId: pConvo.id, name: pConvo.name }}
                                                      fetchPolicy="network-only"
                                                      onCompleted={(data) => {
                                                        // Maybe it sould be done on the server (TESTSET_EDITABLE_CONVO_QUERY), or even in the compilerTXT
                                                        // I was not able to do it deeper in the client. Somehow it was executed more times
                                                        // (the first render updated the data, but the changes where somehow reflected in apollo cache.
                                                        // So the update caused by second render was executed on a data already updated)
                                                        // And:
                                                        // Even this is not enough. On second render apollo goes to the server, and calls this function, but not with the data
                                                        // coming from the server, but by old data. "onCompletedDone" flag is to avoid update
                                                        if (data?.testseteditableconvo && !data.testseteditableconvo.onCompletedDone) {
                                                          dbConvoToForm(data.testseteditableconvo)
                                                          data.testseteditableconvo.onCompletedDone = true
                                                        }
                                                      }}
                                                    >
                                                      {({ loading, error, data }) => {
                                                        if (loading) return <Text padding><LoadingIndicator/></Text>
                                                        if (error) return <ErrorFormat err={error} />
                                                        if(!data.testseteditableconvo) {
                                                          return <ErrorFormat err={new Error(`Something went wrong during reading '${pConvo.name}' Partial Convo`)} />
                                                        }
                                                        return <Form
                                                          mutators={{ ...arrayMutators }}
                                                          onSubmit={async () => {}}
                                                          initialValues={data.testseteditableconvo}
                                                          render={({
                                                                     handleSubmit,
                                                                     values
                                                                   }) => (
                                                            <form onSubmit={handleSubmit} data-simplelist-container>
                                                              <GridContainer>
                                                                <GridItem xs={12}>
                                                                  <ConvoEditor
                                                                    disabled={true}
                                                                    hideName={true}
                                                                    values={values}
                                                                    change={change}
                                                                    testSetId={testSetId}
                                                                    testSetScriptId={data.testseteditableconvo.id}
                                                                    setParentState={setParentState}
                                                                    setTestSetState={setTestSetState}
                                                                    classes={classes}
                                                                    setAlertSuccessMessage={setAlertSuccessMessage}
                                                                    queryEditableConvo={queryEditableConvo}
                                                                    dbConvoToForm={dbConvoToForm}
                                                                  />
                                                                </GridItem>
                                                              </GridContainer>
                                                            </form>
                                                          )}
                                                        />
                                                      }}
                                                    </Query>
                                                    <Button justIcon data-unique={`btnEditReference_${pConvo.id}`}
                                                      onClick={() => history.push(`/testsets/view/${testSetId}/testcases/viewpconvo/${pConvo.id}/${encodeURIComponent(pConvo.name)}`)}>
                                                      <Text regular ><ShowIcon icon="pen" /> Edit Partial Convo</Text>
                                                    </Button>
                                                  </GridItem>
                                                </GridContainer>
                                              </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                          </GridItem>
                                        </GridContainer>
                                      })}
                                      {msg.sender === 'me' && msg.messageText && <>{renderUtteranceText('primary', msg, { fontWeight: 'bold'}, renderUtteranceOnClick)}</>}
                                      {msg.sender === 'me' && msg.userInputs && msg.userInputs.map((a, ai) =>
                                        <ComponentChip component={a} componentType="userInput" key={`userinput_${ai}`} onClick={() => {
                                          this.openEditStepEditor(msg, name, stepIndex, `UI-${msg.userInputs.indexOf(a)}` )
                                        }}/>)}
                                      {msg.sender === 'bot' && msg.messageText && msg.not && <>{renderUtteranceText('danger', msg, { fontWeight: 'bold'}, renderUtteranceOnClick)}</>}
                                      {msg.sender === 'bot' && msg.messageText && !msg.not && <>{renderUtteranceText('white', msg, { fontWeight: 'bold'}, renderUtteranceOnClick)}</>}
                                      {msg.sender === 'bot' && msg.asserters && msg.asserters.map((a, ai) =>
                                        <ComponentChip component={a} componentType="asserter" key={`asserter_${ai}`} onClick={() => {
                                          this.openEditStepEditor(msg, name, stepIndex, `A-${msg.asserters.indexOf(a)}`)
                                        }}/>)}
                                      {msg.logicHooks && msg.logicHooks.map((a, ai) => {
                                        let argsToShow
                                        if(a.name.startsWith('CONDITIONAL_STEP')) {
                                          argsToShow = [a.args[0]]
                                        }
                                        return <ComponentChip component={a} componentType="logichook" argsToShow={argsToShow} key={`logichook_${ai}`} onClick={() => {
                                            this.openEditStepEditor(msg, name, stepIndex, `LH-${msg.logicHooks.indexOf(a)}`)
                                          }}/>
                                      })}
                                      {!disabled && <>
                                        <Tooltip title="Add New & Edit" placement="bottom">
                                          <Button dashedSecondary justIcon noMargin aria-label="Add New & Edit" data-unique="btnTestSetConvoEditorStepAdd" key={`edit_${name}_${stepIndex}`} onClick={() => !disabled && setParentState && setParentState({ editStep: msg, editStepName: name, editStepIndex: stepIndex })} >
                                            <ShowIcon icon="plus" />
                                          </Button>
                                        </Tooltip>
                                        </>}
                                    </ListItemText>

                                    {msg.sender === 'me' && (
                                      <div className={classes.senderUserIcon}>
                                      <ListItemIcon>
                                         <ShowIcon custom icon="senderUser" />
                                      </ListItemIcon>
                                      </div>
                                    )}
                                    {!disabled &&
                                      <DropdownButton
                                        aria-label="Actions"
                                        tooltipTitle="Actions"
                                        dots
                                        items={[
                                          {
                                            id: 'clone',
                                            icon: 'clone',
                                            name: 'Clone',
                                            onClick: () => fields.insert(stepIndex, msg),
                                            dataUnique: 'btnTestSetConvoEditorStepClone'
                                          },
                                          {
                                            id: 'pen',
                                            icon: 'pen',
                                            name: 'Edit',
                                            onClick: () => setParentState && setParentState({ editStep: msg, editStepName: name, editStepIndex: stepIndex }),
                                            dataUnique: 'btnTestSetConvoEditorStepEdit'
                                          },
                                          {
                                            id: 'trash',
                                            icon: 'trash',
                                            name: 'Trash',
                                            onClick: () => fields.remove(stepIndex),
                                            dataUnique: 'btnTestSetConvoEditorStepRemove'
                                          }
                                        ]}
                                      ></DropdownButton>}
                                  </ListItem></div>)}
                            </Draggable>)
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {values.end && <ListItem key="end" >
                  <Field name="end">{() => null}</Field>
                  <ListItemAvatar>
                    <ShowIcon custom icon="endStep" />
                  </ListItemAvatar>
                  <ListItemText
                    tabIndex={0}
                    className={classes.senderNone}
                    data-unique="liTestSetConvoEditorEnd"
                    style={disabled ? {} : { cursor: 'pointer' }}
                    onClick={() => !disabled && setParentState && setParentState({ editStep: values.end, editStepName: 'end' })}
                  >
                    {values.end.asserters && values.end.asserters.map((a, ai) => <ComponentChip componentType="asserter" component={a} key={`asserter_${ai}`} onClick={() => {
                      this.openEditStepEditor(values.end, 'end', 0, `A-${ai}`)
                    }}/>)}
                    {values.end.logicHooks && values.end.logicHooks.map((a, ai) => <ComponentChip componentType="logichook" component={a} key={`logichook_${ai}`} onClick={() => {
                      this.openEditStepEditor(values.end, 'end', 0, `LH-${ai}`)
                    }}/>)}
                    {!disabled && <>
                      <Tooltip title="Add New & Edit" placement="bottom">
                        <Button dashedSecondary justIcon noMargin aria-label="Add New & Edit" data-unique="btnTestSetConvoEditorEndAdd" key={'endEdit'} onClick={() => !disabled && setParentState && setParentState({ editStep: values.end, editStepName: 'end' })} >
                          <ShowIcon icon="plus" />
                        </Button>
                      </Tooltip>
                      </>}
                  </ListItemText>
                  {!disabled &&
                    <DropdownButton
                      aria-label="Actions"
                      tooltipTitle="Actions"
                      dots
                      items={[
                        {
                          id: 'pen',
                          icon: 'pen',
                          name: 'Edit',
                          onClick: () => setParentState && setParentState({ editStep: values.end, editStepName: 'end' }),
                          dataUnique: 'btnTestSetConvoEditorEndCode'
                        },
                        {
                          id: 'trash',
                          icon: 'trash',
                          name: 'Trash',
                          onClick: () => change('end', null),
                          dataUnique: 'btnTestSetConvoEditorEndRemove'
                        }
                      ]}
                    ></DropdownButton>
                  }
                </ListItem>}
              </List>
            </GridItem>
            {!disabled && <GridItem xs={12} largePadding classes={{ root: classes.testsetsUtterancegridbg }}>
              <GridContainer>
                <GridItem xs={3}></GridItem>
                <GridItem xs={6}>
                  <GridContainer>
                    <GridItem xs={4} center borderRight>
                      <Button link className={classes.addStepButton} data-unique="btnTestSetConvoEditorAddConvoStepUser" onClick={() => {
                        const msg = { sender: 'me', messageText: '' }
                        fields.push(msg)
                      }}>
                        <AddIcon />
                        User Step
                      </Button>
                    </GridItem>
                    <GridItem xs={4} center>
                      <Button link className={classes.addStepButton} data-unique="btnTestSetConvoEditorAddConvoStepBot" onClick={() => {
                        const msg = { sender: 'bot', messageText: '', useMatchingMode: MATCHING_MODE_DEFAULT }
                        fields.push(msg)
                      }}>
                        <AddIcon />
                        Bot Step
                      </Button>
                    </GridItem>
                    <GridItem xs={4} center borderLeft>
                      <DropdownButton
                        aria-label="Actions"
                        dots
                        items={moreItems}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={3}></GridItem>
              </GridContainer>
            </GridItem>}
          </React.Fragment>
        }}
      </FieldArray>
    </GridContainer>
  }

}

ConvoEditor.defaultProps = {}

ConvoEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  change: PropTypes.func,
  setParentState: PropTypes.func,
  hideName: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldPrefix: PropTypes.string
}

export default withRouter(
  connect(
    state => state,
    { setAlertSuccessMessage },
  )(withStyles(convoStyle)(withApollo(ConvoEditor))),
)
