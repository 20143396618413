import {
  dangerColor,
  successColor,
  defaultFont
} from 'assets/jss/material-dashboard-react.jsx'
import { isDarkmode } from 'components/Darkmode/helper'
//import { nonExecutableDefinitionMessage } from 'graphql/validation/rules/ExecutableDefinitions'

const customInputStyle = theme => ({
  disabled: {
    '& input': {
      opacity: `0.3 !Important`
    },
    '&:hover': {
      border: `1px solid ${theme.colors.borderColor}!Important`,
      //border: `1px solid ${theme.colors.borderColorhover}`
      borderRadius: 6,
      '& input': {
        border: `0px solid red!Important`,
      }
    },
    '&:before': {
      backgroundColor: 'transparent !important',
      borderWidth: '0 !Important',
      borderBottom: '0 !Important',
    },
  },
  input: {
    height: '43px !Important',
    paddingLeft: '12px !Important',
    fontSize: '13px !Important',
    fontWeight: '500 !Important',
    boxSizing: 'border-box',
    '&:-webkit-autofill': {
      WebkitTextFillColor: `${theme.colors.expansionbordercontenttext} !Important`,
      transition: 'background-color 5000s ease-in-out 0s'
    },
    '&::-webkit-calendar-picker-indicator': {
      marginRight: 18,
      filter: isDarkmode() ? 'invert(0.8)' : 'none',
      cursor: 'pointer'
    },
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
      background: '#FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center',
      width: '1em',
      borderLeft: '1px solid #BBB',
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      opacity: .5, /* shows Spin Buttons per default (Chrome >= 39) */
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      '&:hover,&:active': {
          boxShadow: '0 0 2px #0CF',
          opacity: .8
      }
  },
    color: `${theme.colors.expansionbordercontenttext} !Important`,
    '&::after': {
      borderWidth: 0,
      borderBottomColor: 'yellow !Important'
    },
    '&::placeholder':{
      fontWeight: 400,
      color: '#5e6c84',
      opacity: 1
    },

    '&:-ms-input-placeholder':{
      fontWeight: 400,
      color: '#5e6c84',
      opacity: 1
    },

    '&::-ms-input-placeholder':{
      fontWeight: 400,
      color: '#5e6c84',
      opacity: 1
    }
  },
  inputTextArea: {
    paddingLeft: '24px !Important',
    fontSize: '13px !Important',
    fontWeight: '500 !Important',
    boxSizing: 'border-box',
    resize: 'vertical',
    color: `${theme.colors.expansionbordercontenttext} !Important`,
    '&::after': {
      borderWidth: 0,
      borderBottomColor: 'yellow !Important'
    },
  },  
  inputNoBorder: {
    margin: '0px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    paddingLeft: '24px !Important',
    height: '14px !important'
  },
  inputTextAreaNoBorder: {
    margin: '0px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    paddingLeft: '24px !Important'
  },
  inputError: {
    color: `${theme.colors.common.danger} !Important`,
  },
  inputPassword: {
    fontSize: '20px !Important',
  },
  inputFormControlTextRoot: {
    position: 'relative',
  },
  formControl: {
    margin: '5px 0 !Important',   
    position: 'relative',
    border: `1px solid ${theme.colors.borderColor}`,
    paddingRight: 3,
    borderRadius: 6,
    '&:before': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
    '&::after': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
    '&:hover': {
      border: `1px solid ${theme.colors.borderColorhover}`,
      '&:before': {
        borderWidth: '0 !Important',
        borderBottom: '0 !Important'
      }
    }
  },
  formControlNoBorder: {
    margin: '0 !Important',   
    position: 'relative',
    height: 20,
    border: 'none !Important',
    '&:before': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
    '&:hover': {
      border: 'none !Important',
      '&:before': {
        borderWidth: '0 !Important',
        borderBottom: '0 !Important'
      }
    },
    '& input': {
      margin: '0 0 0 10px !Important',
      padding: '0 !Important',
    }
  },
  formControlTextAreaNoBorder: {
    margin: '0 !Important',   
    position: 'relative',
    border: 'none !Important',
    '&:before': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
    '&:hover': {
      border: 'none !Important',
      '&:before': {
        borderWidth: '0 !Important',
        borderBottom: '0 !Important'
      }
    },
    '& input': {
      margin: '0 0 0 10px !Important',
      padding: '0 !Important',
    }
  },
  formControlNoError: {
    '&:after': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      borderWidth: '0 !Important',
      borderBottom: '0 !Important'
    },
    '&:after': {
      borderWidth: 0
    },
    '&:before': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
  },
  underlineError: {
    '&:after': {
      borderColor: dangerColor,
    },
  },
  underlineSuccess: {
    '&:after': {
      borderColor: successColor,
    },
  },
  labelRoot: {
    ...defaultFont,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '1.42857',
  },
  labelRootError: {
    color: dangerColor,
  },
  labelRootSuccess: {
    color: successColor,
  },
  // helper text start
  inputHelperTextRoot: {
    paddingLeft: 3,
    paddingRight: 0,
    marginBottom: 10,
    fontSize: 12,
    color: `${theme.colors.inputlabel}`,
    lineHeight: '14px',
    opacity: 1,
    fontWeight: 'normal'
  },
  inputHelperDisabled: {
    color: `${theme.colors.inputlabel}!Important`,
    opacity: 0.4,
  },
  inputHelperTextRootHide: {
    display: 'none'
  },
  // helper text end 
  // label start
  inputLabelRoot: {
    paddingLeft: '3px !important',  
    marginTop: 0,
    color: `${theme.colors.inputlabel} !important`,
    //fontSize: '12px !important',
    '& b' :{
      color: `${theme.colors.inputlabel}`,
      fontSize: '12px !important',
    }
    // width: '100%'
  },
  inputLabelRootNoBorder: {
    paddingLeft: '25px !important',  
    marginTop: 0,
    color: `${theme.colors.inputlabel} !important`,
    //fontSize: '12px !important',
    '& b' :{
      color: `${theme.colors.inputlabel}`,
      fontSize: '12px !important',
    }
    // width: '100%'
  },
  inputLabelShrink:{
    transform: 'translate(0, 1px) scale(1) !important',
  },
  inputLabelformControl:{
    position: 'relative !Important',
    paddingBottom: 5,
    paddingLeft: 3,
    fontSize: '12px !important',
    fontWeight: 500,
    '& div':{
      fontSize: '12px !Important',
      marginLeft: 8
    }
  },
 
  inputLabeldisabled: {
    opacity: 0.30,
  },
  
  


  inputAdornedEnd: {
    
    width: '100%',

    '& input': {
      margin: '0 -3px 0 -24px !Important',
      padding: '0 0 0 26px !Important',
      height: '30px !Important',
      borderRadius: 5,
    },
  
    cursor: 'pointer!Important',

    '&:hover': {

      '& input': {
        cursor: 'pointer!Important'
      },
  
      '& svg path': {
        fill: `${theme.colors.common.secondary} !important`,
      },
      
    },

    '& input:focus': {
      border: `1px solid ${theme.colors.common.secondary} !important`,
      '& svg path':{
         fill: `${theme.colors.common.secondary} !important`,
      }
    },

   
    '& svg': {
      display: 'block',
      color: theme.colors.primary,
      marginLeft: 5,
      height: 13,
      cursor: 'pointer',
    },

    
  },

  inputAdornedEndDanger: {
    '& svg': {
      color: dangerColor
    }
  },
  inputAdornedStart:{
    '& input:focus ~ svg': {
      fill: 'red',
      color: 'red',
  },
  },
  // label end

  switchBasecolorbar: {
    backgroundColor: `${theme.colors.common.secondary} !Important`,
  },

  switchBasecolorchecked: {
    color: `${theme.colors.common.secondary} !Important`,
  },
  switchBasecolorunchecked: {
    backgroundColor: `${theme.colors.switchunchecked} !Important`,
  },
  switchBasecolorlabel: {
    marginTop: 18,
    '& span:nth-child(2)': {
      color: `${theme.colors.primary} !Important`,
    }
  },
  switchBasecolornolabel: {
    marginTop: 0,
    '& span:nth-child(2)': {
      color: `${theme.colors.primary} !Important`,
    }
  },
  checkboxBase: {
    color: `${theme.colors.primary} !Important`,
  },
  checkboxBasedense: {
    color: `${theme.colors.primary} !Important`,
    margin: '0px',
    padding: '0px'
  },
  checkboxBasecolorchecked: {
    color: `${theme.colors.primary} !Important`,
  },
  feedback: {
    position: 'absolute',
    top: '18px',
    right: '0',
    zIndex: '2',
    display: 'block',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  marginTop: {
    marginTop: '16px',
  },
  marginTopOff: {
    marginTop: '0px',
  },
  formControlLabelLabelSmall: {
    fontSize: 12,
    color: theme.colors.primary
  },
  labelShrinkWidth: {
    width: '130%'
  },
  filled: {
    marginTop: 160,
  },
  sliderLabel: {
    width: '75%', 
    position: 'relative',
    top: '-22px !Important',
    left: 6, 
    fontWeight: 500,
    fontSize: '12px !Important',
    transform: 'none !Important',
    color: `${theme.colors.inputlabel} !important`,
    //fontSize: '12px !important',
    '& b' :{
      color: `${theme.colors.inputlabel}`,
      fontSize: '12px !important',
    }
  },
  sliderFormControl: {
    marginTop: 38
  },
  slideHelperText: {
    color: `${theme.colors.primary} !Important`,
    marginLeft: 6
  },
  sliderInput: {
    marginTop: '0',
    width: '75% !Important',
    backgroundColor: 'transparent !Important',
    borderRadius: '50px',
    '&::-webkit-slider-thumb ': {
      backgroundColor: `${theme.colors.common.white} !Important`,
      borderColor: `${theme.colors.common.primary} !Important`,
      height: '30px  !Important',
      width: '30px  !Important',
      position: 'relative',
      marginTop: '-7px !Important',
    },
    '&::-webkit-slider-runnable-track': {
      height: '16px  !Important',
      borderRadius: 64,
      backgroundColor: `${theme.colors.common.sliderBg} !Important`,
    }
  },
  fullWidth: {
    width: '100%',
  },
  flexibleWidth: {
    minWidth: 200,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  autocompleteInput: {
    margin: '5px 0 !Important',   
    position: 'relative',
    border: `1px solid ${theme.colors.borderColor}`,
    paddingRight: 3,
    borderRadius: 6,
    '&:before': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
    '&::after': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
    '&:hover': {
      border: `1px solid ${theme.colors.borderColorhover}`,
      '&:before': {
        borderWidth: '0 !Important',
        borderBottom: '0 !Important'
      }
    },
    '& input': {
      height: '43px !Important',
      paddingLeft: '12px !Important',
      fontSize: '13px !Important',
      fontWeight: '500 !Important',
      boxSizing: 'border-box',
      width: '100%',
      border: 0,
      background: 'transparent',
      color: theme.colors.borderColorhover,
      '& div': {
        minWidth: 245,
        overflow: 'scroll',
      }
    },
  },
  disabledCodeMirror: {
   opacity: 0.3,
   '& .CodeMirror-lines': {
    backgroundColor:  theme.colors.menuBg,
    cursor: 'default',
   },
   '& .cm-variable, .cm-property': {
    color: `${theme.colors.primary} !Important`,
   },
   '& .CodeMirror-gutters': {
    borderRight: `1px solid ${theme.colors.borderColor} !Important`
   },
   '& .CodeMirror': {
    height: 'fit-content!Important'
   }
  }
})

export default customInputStyle
