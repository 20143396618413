import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
// apollo
import { Query, Mutation, compose, graphql, withApollo } from 'react-apollo'
// core components
import Chip from 'components/Chip/Chip'
import Button from 'components/Button/Button'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { renderTextField, required, composeValidators, renderTextArea } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TESTSET_EDITABLE_UTTERANCE_QUERY,
  TESTSET_EDITABLE_UTTERANCE_UPSERT,
  TESTSETSCRIPT_QUERY,
  TESTSETS_DROPDOWN_QUERY,
  SPEECH_APPLY_ON_UTTERANCE,
  RefetchTestSetQueries, TESTSETSCRIPTREFERENCES_QUERY
} from './gql'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import CardBody from 'components/Card/CardBody.jsx'
import Card from 'components/Card/Card.jsx'
import { renderSelect, FormActionsToolbar } from 'components/Form/Form.js'
import SwitchButton from 'components/Button/SwitchButton.jsx'
import Text from 'components/Typography/Text'
import { validateUtteranceNameUnique } from './validators'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Table from 'components/Table/AdvancedTable'
import { safeGetNamespaceFilteredList } from '../helper'

import { SpeechTTSSelectionPanel, SpeechEffectsSelectionPanel, SpeechJobsTable } from '../TestDataWizard/VoiceHelperComponents'
import { OnChange } from 'react-final-form-listeners'
import Tooltip from 'components/Tooltip/Tooltip'
import Divider from 'components/Divider/Divider'

const formToUpsertUtterance = (values) => {
  return {
    name: values.name,
    skip: !!values.skip,
    utterances: values.utterances ? values.utterances.map(u => u || '') : []
  }
}

class TestSetUtterance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      deleting: false,
      copying: false,
      moving: false,
      ignoreQuery: false,
      showParaphrasesDialog: false,
      showApplySpeechDialog: false,
      applySpeechInitialData: {
      }
    }
    this.empty = { name: 'UTT_NAME', utterances: ['first user example', 'second user example'] }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  validateUtteranceDuplicates(utterances) {
    const errors = []
    if (!this.hasWritePermission()) return errors

    if (utterances && utterances.length > 1) {
      for (let i = 0; i < utterances.length; i++) {
        if (utterances.findIndex((u, ui) => (u || '') === (utterances[i] || '') && i !== ui) >= 0) {
          errors[i] = 'Duplicated user examples'
        }
      }
    }
    return errors
  }

  renderForm(testSetId, testSetScriptId, name, utterance, warnings) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, features, classes, setParentState, match } = this.props
    const { saving } = this.state
    const aggregatedWarnings = []
    for (const warning of (warnings || [])) {
      const foundWarn = aggregatedWarnings.find(aggWarn => warning.name === aggWarn.name)
      if (foundWarn) {
        foundWarn.description.push(<li key={warning.description}>{warning.description}</li>)
      } else {
        const aggregatedWarning = _.cloneDeep(warning)
        aggregatedWarning.description = [<li key={aggregatedWarning.description}>{aggregatedWarning.description}</li>]
        aggregatedWarnings.push(aggregatedWarning)
      }
    }

    return (<>
      <Mutation
        mutation={TESTSET_EDITABLE_UTTERANCE_UPSERT}
        refetchQueries={({ data }) => [
          ...RefetchTestSetQueries(testSetId, license),
          {
            query: TESTSET_EDITABLE_UTTERANCE_QUERY,
            variables: { testSetScriptId: data.upsertEditableUtterance.scriptId, name: data.upsertEditableUtterance.name }
          },
          {
            query: TESTSETSCRIPT_QUERY,
            variables: { id: data.upsertEditableUtterance.scriptId },
          }
        ]}
      >
        {(upsertUtterance, { loading }) => (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={async (values, form) => {
              this.setState({ saving: true })
              try {
                const res = await upsertUtterance({
                  variables: {
                    testSetId,
                    testSetScriptId,
                    name: name || values.name,
                    utterance: formToUpsertUtterance(values)
                  }
                })
                form.initialize(res.data.upsertEditableUtterance)
                this.setState({ saving: false })
                history.push(`/testsets/view/${testSetId}/testcases/viewutterance/${res.data.upsertEditableUtterance.scriptId}/${encodeURIComponent(res.data.upsertEditableUtterance.name)}`)
                setAlertSuccessMessage('Utterance List ready for use')
              } catch (error) {
                this.setState({ saving: false })
                setAlertErrorMessage(`Saving Utterance List failed`, error)
              }
            }}
            initialValues={utterance}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values
            }) => (
              <form onSubmit={handleSubmit} data-simplelist-container>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} borderBottom header>
                    <GridContainer autoHeight>
                      <GridItem md={12} lg={8} className={classes.utteranceName}>
                      <Tooltip title={values.name || ''}>
                        <Field
                          name="name"
                          component={renderTextField}
                          validate={composeValidators(required, async (value) => value ? validateUtteranceNameUnique(this.props.client, testSetId, testSetScriptId, value) : null)}
                          disableBorder
                          inlineEdit
                          slimError
                          flexibleWidth
                          disabled={!this.hasWritePermission()}
                          data-unique="txtTestSetUtteranceListName"
                        />
                        </Tooltip>
                      </GridItem>
                      <GridItem md={12} lg={4} right top smallMarginTop className={classes.switchButtonCol}>
                        <SwitchButton leftLabel="Source Editor" rightLabel="Utterance Editor" leftDataUnique="btnSourceEditor" rightDataUnique="btnUtteranceEditor" checked="right" onLeftClick={() => {
                          setParentState({ testCaseViewMode: 'source' })
                          if (match.params.testSetScriptId) {
                            history.push(`/testsets/view/${match.params.testSetId}/testcases/viewscript/${match.params.testSetScriptId}`)
                          } else {
                            history.push(`/testsets/view/${match.params.testSetId}/testcases/registerscript/utterance`)
                          }
                        }} />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12}>
                    <GridContainer padding>
                      <GridItem xs={12}>
                        {aggregatedWarnings && aggregatedWarnings.length > 0 && <GridContainer>
                          {aggregatedWarnings.map((w, wi) => <GridItem xs={12} key={wi}>
                            {w.severity === 'ERROR' && <Chip variant="error" label={w.name} tooltip={w.description} />}
                            {w.severity === 'WARNING' && <Chip variant="warning" label={w.name} tooltip={w.description} />}
                          </GridItem>)}
                        </GridContainer>}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <FieldArray name="utterances" validate={(values) => this.validateUtteranceDuplicates(values)}>
                    {({ fields }) => (<React.Fragment>
                      <GridItem xs={12} classes={{ root: classes.testsetsUtterancegridbg }}>
                        <GridContainer>
                          {fields.map((name, index) => (<React.Fragment key={`utterance_${index}`}>
                            <GridItem floatLeft key={`utterance_${index}`}>
                              <Card smallMargin>
                                <CardBody noPadding>
                                  <GridContainer>
                                    <GridItem>
                                      <Field
                                        className={classes.testsetsUtteranceFieldGrid}
                                        name={name}
                                        disableBorder
                                        slimError
                                        inlineEdit
                                        flexibleWidth
                                        component={renderTextArea}
                                        label={`User Example #${index + 1}`}
                                        placeholder="Type utterance ..."
                                        disabled={!this.hasWritePermission()}
                                        data-unique={`txtTestSetUtterance_${index}`}
                                      />
                                      <OnChange name={name}>
                                        {(value) => {
                                          fields.update(index, value.replace(/[\r\n\v]+/g, ''))
                                        }}
                                      </OnChange>
                                    </GridItem>
                                    <GridItem middle right classes={{ root: classes.testsetsUtteranceDeleteButtonGrid }}>
                                      <Tooltip title="Delete">
                                        <Button justIcon aria-label="Delete" data-unique="btnTestSetUtteranceDelete" onClick={() => fields.remove(index)}>
                                          <ShowIcon icon="trash" />
                                        </Button>
                                      </Tooltip>
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                          </React.Fragment>))}
                          {this.hasWritePermission() && <GridItem floatLeft>
                            <div className={classes.testsetsUtteranceAddButtonGrid}>
                              <Tooltip title="Add New Example">
                                <Button dashed secondary justIcon aria-label="Add New Example" data-unique="btnTestSetUtteranceAdd" onClick={() => fields.push('')} className={classes.testsetsUtteranceAddButton}>
                                  <ShowIcon icon="plus" />
                                </Button>
                              </Tooltip>
                            </div>
                          </GridItem>}
                        </GridContainer>
                      </GridItem>
                    </React.Fragment>)}
                  </FieldArray>
                </GridContainer>
                <GridContainer padding>
                  <GridItem xs={12}>
                    <FormActionsToolbar
                      leftButtons={<>
                        {license.paraphrasing && this.hasWritePermission() && this.renderParaphraseButtons(testSetId, testSetScriptId, name)}
                        {this.hasWritePermission() && features && features.speechService && utterance && <>
                        <Button
                          secondary
                          dashed
                          data-unique="btnTestSetUtteranceApplySpeech"
                          disabled={saving || submitting}
                          onClick={() => {
                            this.setState({
                              showApplySpeechDialog: true,
                              speechTestSetId: testSetId,
                              speechUtterance: formToUpsertUtterance(values),
                              speechApplyOnUtteranceResult: null,
                              speechApplyOnUtteranceErr: null,
                              applySpeechInitialData: {
                                ...this.state.applySpeechInitialData
                              }
                            })
                          }}
                        >
                          <ShowIcon icon="comment" />
                          Transform to Voice
                        </Button>
                      </>}

                      </>}
                      rightButtons={
                        <React.Fragment>
                          {this.hasWritePermission() &&
                            <Button
                              type="submit"
                              disabled={saving || submitting || invalid}
                              data-unique="btnTestSetUtteranceSave"
                            >
                              {saving && <><LoadingIndicator alt /> Saving ...</>}
                              {!saving && <><ShowIcon icon="save" /> Save</>}
                            </Button>
                          }
                        </React.Fragment>
                      } />
                  </GridItem>
                  {testSetScriptId && <GridItem xs={12}>
                    <Query query={TESTSETSCRIPTREFERENCES_QUERY} variables={{ testSetScriptId }}>
                      {({ loading, error, data }) => {
                        return (
                          <GridContainer>
                            <Divider orientation="horizontal" noMarginBottom />
                            <GridItem xs={12} >
                              <Text header className={classes.textConvosHeader}>Convos where utterance is in use</Text>
                              <Table
                                tableHeaderColor="primary"
                                tableHead={[
                                  'Convo',
                                  'Test Set',
                                  { name: 'Actions', right: true }
                                ]}
                                tableData={
                                  data.testsetscriptreferences && data.testsetscriptreferences.map(ref => [
                                    () => (
                                      <GridContainer>
                                        <GridItem middle xs={2}>{ref.scriptType === 'SCRIPTING_TYPE_PCONVO' ? <ShowIcon custom icon="partialConvo" /> : <ShowIcon custom icon="convo" />}</GridItem>
                                        <GridItem middle xs={10}><Text>{ref.name}</Text></GridItem>
                                      </GridContainer>),
                                    () => (<GridItem middle xs={10}><Text>{ref.testSet.name}</Text></GridItem>),
                                    () => (
                                      <Button justIcon data-unique={`btnShowReference_${ref.id}`} onClick={() => ref.scriptType === 'SCRIPTING_TYPE_PCONVO' ? history.push(`/testsets/view/${ref.testSet.id}/testcases/viewpconvo/${ref.id}/${ref.name}`) : history.push(`/testsets/view/${ref.testSet.id}/testcases/viewconvo/${ref.id}/${ref.name}`)}>
                                        <ShowIcon icon="eye" />
                                      </Button>)
                                  ])
                                }
                              />
                            </GridItem>
                          </GridContainer>
                        )}}
                    </Query>
                  </GridItem>}
                </GridContainer>
              </form >
            )
            }
          />
        )}
      </Mutation >
      {this.hasWritePermission() && features && features.speechService && utterance && this.renderSpeechApplyDialog()}
    </>)
  }

  renderParaphraseButtons(testSetId, scriptId, scriptName) {
    return <Button
      secondary
      dashed
      onClick={() => this.props.history.push(`/testsets/view/${testSetId}/testcases/viewutterance/${scriptId}/${encodeURIComponent(scriptName)}/paraphraser`)}
      data-unique="btnTestSetUtteranceParaphrase"
    >
      Paraphrase it!
    </Button>
  }

  renderSpeechApplyDialog() {
    const { mutateSpeechApplyOnUtterance, testSetsData, history } = this.props
    const { speechTestSetId, speechUtterance, speechApplyOnUtteranceResult, speechApplyOnUtteranceErr, applySpeechInitialData } = this.state

    const _resetInitialData = (values) => {
      if (!speechApplyOnUtteranceResult) return
      const initialData = { ...applySpeechInitialData }
      initialData.testSetId = speechApplyOnUtteranceResult.testSetId
      initialData.newTestSetName = null
      this.setState({ applySpeechInitialData: initialData })
    }

    return (<Form
      onSubmit={async (values) => {
        try {
          const input = {}
          if (values.newTestSetName) {
            input.targetTestSetName = values.newTestSetName
          } else if (values.testSetId) {
            input.targetTestSetId = values.testSetId
          } else {
            input.targetTestSetId = speechTestSetId
          }
          const { data } = await mutateSpeechApplyOnUtterance({
            variables: {
              input: {
                ...input,
                utterance: speechUtterance,
                profileId: values.profile,
                language: values.language,
                voices: [values.voices],
                effectsProfileId: values.effectsProfile,
                effects: values.effects
              }
            }
          })
          this.setState({ speechApplyOnUtteranceErr: null, speechApplyOnUtteranceResult: data.speechApplyOnUtterance })
        } catch (err) {
          this.setState({ speechApplyOnUtteranceErr: err, speechApplyOnUtteranceResult: null })
        }
      }}
      initialValues={applySpeechInitialData}
      render={({
        handleSubmit,
        submitting,
        values,
        form
      }) => (
        <ConfirmationDialog
          cancelText="Close"
          open={this.state.showApplySpeechDialog}
          onCancel={() => {
            if (speechApplyOnUtteranceResult) {
              _resetInitialData(values)
            }
            this.setState({ showApplySpeechDialog: false })
          }}
          onOk={() => {
            if (speechApplyOnUtteranceResult) {
              _resetInitialData(values)
              this.setState({ showApplySpeechDialog: false })
              history.push(`/testsets/view/${speechApplyOnUtteranceResult.testSetId}/filebrowser?path=${speechApplyOnUtteranceResult.mediaDir.join('/')}`)
            } else {
              handleSubmit()
            }
          }}
          okText={speechApplyOnUtteranceResult ? 'Show Voice Files' : 'Start Transform'}
          okDisabled={!!submitting}
          title="Transform to Voice Files"
        >
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <Field
                  name="testSetId"
                  component={renderSelect}
                  label="Save to Test Set"
                  data-unique="selTestSetUtteranceTestSetId"
                  disabled={!!speechApplyOnUtteranceResult || !!values.newTestSetName}
                  items={testSetsData && testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => {
                    return {
                      key: t.id,
                      label: t.name
                    }
                  })}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="newTestSetName"
                  component={renderTextField}
                  label="Save to new Test Set"
                  disabled={!!speechApplyOnUtteranceResult || !!values.testSetId}
                  data-unique="txtTestSetUtteranceNewTestSetName"
                />
              </GridItem>
              <GridItem xs={12}>
               <SpeechTTSSelectionPanel form={form} values={values} disabled={!!speechApplyOnUtteranceResult} multiple={false}/>
              </GridItem>
              <GridItem xs={12}>
                <SpeechEffectsSelectionPanel form={form} values={values} disabled={!!speechApplyOnUtteranceResult}/>
              </GridItem>
              {speechApplyOnUtteranceResult && speechApplyOnUtteranceResult.jobIds &&
                <GridItem xs={12}>
                  <SpeechJobsTable ids={speechApplyOnUtteranceResult.jobIds} />
                </GridItem>
              }
              {speechApplyOnUtteranceErr &&
                <GridItem xs={12}>
                  <ErrorFormat err={speechApplyOnUtteranceErr} suppress />
                </GridItem>
              }
            </GridContainer>
          </form>
        </ConfirmationDialog>
      )}
    />)
  }

  render() {
    const { match, history } = this.props

    if (match.params && match.params.testSetScriptId && match.params.name) {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Query
              query={TESTSET_EDITABLE_UTTERANCE_QUERY}
              variables={{ testSetScriptId: match.params.testSetScriptId, name: match.params.name }}
            >
              {({ loading, error, data }) => {
                if (loading) return <Text padding><LoadingIndicator /></Text>
                if (error) return <ErrorFormat err={error} />
                if (!data.testseteditableutterance) {
                  history.push(`/testsets/view/${match.params.testSetId}/testcases/registerutterance/`)
                  return this.renderForm(match.params.testSetId, null, null, this.empty)
                }
                return this.renderForm(match.params.testSetId, match.params.testSetScriptId, match.params.name, data.testseteditableutterance, data.testseteditableutterancewarnings)
              }}
            </Query>
          </GridItem>
        </GridContainer>
      )
    } else {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderForm(match.params.testSetId, null, null, this.empty)}
          </GridItem>
        </GridContainer>
      )
    }
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace, features: state.settings.features }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(TESTSETS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      testSetsData: data,
    }),
  }),
  graphql(SPEECH_APPLY_ON_UTTERANCE, {
    props: ({ mutate }) => ({
      mutateSpeechApplyOnUtterance: args => mutate(args)
    }),
    options: (props) => ({
      refetchQueries: ({ data }) => [
        ...RefetchTestSetQueries(data.speechApplyOnUtterance.testSetId, props.license),
      ]
    })
  }),
  withApollo
)(TestSetUtterance)
