import dashboardStyle from './dashboardStyle.jsx'
import convoStyle from '../components/convoStyle.jsx'
import { defaultBoxShadow, whiteColor } from 'assets/jss/material-dashboard-react.jsx'

const chip = {
  height: 'auto',
  color: 'white',
}

const testsetsStyle = theme => ({
  ...dashboardStyle(theme),
  ...convoStyle(theme),
  graphContainer: {
    marginTop: 10,
    height: 800,
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 3
  },
  graphMe: {
    ...defaultBoxShadow,
    transition: 'none',
    backgroundColor: '#f2f2f2',
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 15,
    padding: theme.spacing.unit,
    marginLeft: 5,
    width: 400,
    minHeight: 50,
    pointerEvents: 'all'
  },
  graphMeHighlighted: {
    backgroundColor: '#f5bf42'
  },
  graphAvatarMe: {
    float: 'right'
  },
  graphBot: {
    ...defaultBoxShadow,
    transition: 'none',
    backgroundColor: '#2A3F54',
    borderRadius: 15,
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
    padding: theme.spacing.unit,
    marginLeft: 5,
    width: 400,
    minHeight: 50,
    pointerEvents: 'all'
  },
  graphBotHighlighted: {
    backgroundColor: '#f5bf42'
  },
  graphSelected: {
    borderColor: 'orange'
  },
  graphAvatarBot: {
    float: 'right'
  },
  graphEdge: {
  },
  graphHandle: {
  },
  chipInfo: {
    ...chip,
    backgroundColor: theme.colors.common.info,
    '& span':{
      color: whiteColor
    }
  },
  chipSuccess: {
    ...chip,
    backgroundColor: theme.colors.common.success
  },
  chipWarning: {
    ...chip,
    backgroundColor: theme.colors.common.warning
  },
  chipDanger: {
    ...chip,
    backgroundColor: theme.colors.common.danger
  },
  chipDashed: {
    ...chip,
    backgroundColor: 'transparent',
    border: `1px dashed ${theme.colors.common.secondary}`,
    '& span': {
      color: theme.colors.common.primary
    }
  },
  chipDisabled: {
    opacity: '0.65',
    pointerEvents: 'none',
  },

  quickcreattestsetstartbutton: {
    position: 'relative',
    top: '59px !Important',
  },

  Startboxtitle: {
    color: theme.colors.primary,
    fontSize: 12,
    fontWeight: 'normal',
    position: 'relative',
    top: 8,
  },

  cardBoxBorder: {
    border: `1px solid ${theme.colors.borderColor} !important`,
    borderRadius: 6,
  },

  cardTitleWhite: {
    color: theme.colors.primary,
    fontSize: 18,
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
  },

  cardCategoryWhite: {
    color: theme.colors.primary,
    fontSize: 13,
    padding: 0,
    margin: 0,
  },
  cardgdprcontent: {
    padding: '0 0 10px 10px'
  },

  leftDatacards: {
    paddingTop: 25,
  },

  dashboardbutton: {
    color: theme.colors.primary,
  },
  NavLink: {
    display: 'block',
    marginTop: 45,
  },
  testCaseEditorContiner: {
    border: `1px solid ${theme.colors.borderColor}`,
    borderRadius: 6,
    width: '100%',
    margin: '0 12px !Important',
  },
  testsetsUtteranceGrid: {
    paddingLeft: 25,
    paddingTop: 25,
    paddingBottom: 25
  },
  testsetsUtteranceAddButtonGrid: {
    height: 96,
    paddingTop: 10
  },
  testsetsUtteranceAddButton: {
    margin: '2px 0 0 0', height: 70, width: 70, textAlign: 'center', borderColor: theme.colors.primary
  },
  testsetsUtteranceFieldGrid: {
    maxWidth: '100%',
    position: 'relative',
    top: -4,
    paddingLeft: 15,
    '& label': {
      paddingLeft: '0!important'
    },
    '& input': {
      margin: '0 0 0 -21px !Important',
      padding: '0 0 0 26px !Important',
    },
    '& textarea': {
      marginLeft: '-20px!important'
    },
    '& svg': {
      height: 11,
      marginLeft: '0!important'
    },
    '& p':{
      margin: 0,
    }
  },
  testsetsUtteranceDeleteButtonGrid: {
    width: 40,
  },
  testsetsUtterancegridbg: {
    backgroundColor: theme.colors.testsetseditorbg,
  },
  testsetsUtteranceedit: {
    '& div': {
       marginTop: 25,
    },
    height: 68,
  },
  testsetsUtteranceswitch: {
    paddingTop: 13,
    maxWidth: '100%'
  },
  convoStepEditorCardButton: {
    width: 140,
    height: 90,
    margin: 5
  },
  convoStepEditorScrollableContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '70vh',
    paddingBottom: 240,
    scrollbarWidth: 0,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  convoStepEditorScrollableContainerConfiguration: {
    overflow: 'scroll',
    maxHeight: '55vh',
    paddingBottom: 140,
    scrollbarWidth: 0,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  convoStepEditorScrollableContainerComponents: {
    overflow: 'scroll',
    maxHeight: '45vh',
    paddingBottom: 140,
    scrollbarWidth: 0,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  convoStepEditorFilterContainer: {
    backgroundColor: theme.colors.backgroundPrimary,
    position: 'relative',
    display: 'block',
    width: '99%',
    top: '-11px',
  },
  testSetConvoEditorRemoveButton: {
    paddingTop: 11,
    textAlign: 'left',
    '& button' :{
      margin: 0,
    }
  },
  paraphraseUtterancesHeader: {
    minHeight: 68
  },
  paraphraseSelected: {
    border: `1px solid ${theme.colors.common.secondary} !important`,
  },
  headertopmargin: {
    marginTop: 25,
  },
  testCaseFilterLabel: {
    marginTop: 0
  },
  optionalStepButtonContainer: {
    width: '100%',
    marginLeft: 25,
    position: 'relative',
    top: '-10px'
  },
  testcasesViewAllContainer: {
    paddingLeft: 10
  },
  generatebuttonposition: {
    position: 'relative',
    top: -4
  },
  justIcon: {
    '& svg':{
      width: 16,
      height:16,
    }
  },
  tablePosition: {
    position: 'relative',
    top: '-80px'
  },
  namespace: {
    border: `1px solid ${theme.colors.borderColorhover} !important`,
    borderRadius: 5,
    padding: '3px 10px'
  },
  embeddedTableNamespace: {
    '& a':{
      '& div':{
        top: '0!Important'
      }
    }
  },
  embeddedTable: {
    '& table': {
      '& tr': {
        height: 70
      },
      '& tr:last-child td': {
        borderBottom: `0px solid ${theme.colors.tableRowBorder}`
      },
    }
  },
  audioChip: {
    position: 'relative',
    left: -27,
    paddingLeft: 3,
  },
  audioPlayButton: {
    position: 'relative',
    left: 15,
    zIndex: 1,
    color: `${theme.colors.common.primary}!important`
  },
  textConvosHeader: {
    position: 'relative',
    bottom: -50,
    fontSize: 18,
    fontWeight: 400
  },
  testsetServerSidePagingTable: {
    '& table': {
      '& tr': {
        height: 70
      },
      '& tr:last-child td': {
        borderBottom: `0px solid ${theme.colors.tableRowBorder}`
      },
    }
  },
  gridWidthLeft: {
    maxWidth: '25%',
    flexBasis: '25%'
  },
  gridWidthRight: {
    maxWidth: '75%',
    flexBasis: '75%'
  },
  valuebuble: {
    backgroundColor: theme.colors.common.secondary,
    color: theme.colors.common.primary,
    fontWeight: 'bold',
    marginLeft: 14,
    marginRight: 4,
    borderRadius: 15,
    padding: '2px 8px 2px 8px'
  },
  valuebubleempty: {
    backgroundColor: 'transparent',
    color: 'transparent',
    fontWeight: 'bold',
    marginLeft: 14,
    marginRight: 4,
    borderRadius: 15,
    padding: '2px 8px 2px 8px'
  },
  dropdownCheckboxFullWidth: {
    margin: 0,
    borderRadius: 6,
    width: '100%'
  },
  scatterChart: {
    margin: `35px 0`,
    '& svg': {
        '& line': {
            stroke: `${theme.colors.borderColor}`
        }
    }
  },
  stepsBubble: {
    color: theme.colors.primary,
    border: `1px solid ${theme.colors.borderColor}`,
    marginRight: 15,
    padding: '4px 10px',
    borderRadius: 6,
    fontWeight: 'bold'
  },
  stepsDivider: {
    width: 60,
    height: 1,
    backgroundColor: theme.colors.tableRow,
    marginLeft: 15,
    marginRight: 25,
    marginTop: 25,
    marginBottom: 25
  },
  linkName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'anywhere',
  },
  filtersPadding: {
    paddingTop: 25,
    paddingLeft: 0,
  },
  paraphraseAiCommands: {
    marginLeft: 18,
  },
  utteranceName: {
    '& div': {
       marginTop: 12,
       width: '100%',
       marginRight: 25,
       height: 30,
       borderRadius: 5,
    },
    '& div:hover': {
      backgroundColor: `${theme.colors.common.primary}`
    }
  },
  '@media (max-width: 1480px)': {
    gridWidthLeft: {
      maxWidth: '30%',
      flexBasis: '30%'
    },
    gridWidthRight: {
      maxWidth: '70%',
      flexBasis: '70%'
    }
  },
  '@media (max-width: 1279px)': {
    gridWidthLeft: {
      maxWidth: '32%!Important',
      flexBasis: '32%!Important'
    },
    gridWidthRight: {
      maxWidth: '68%!Important',
      flexBasis: '68%!Important'
    },
    col1: {
      order: 1,
    },
    col2: {
      order: 3,
    },
    col3: {
      order: 2,
    },
    col4: {
      order: 4,
    },
    col5: {
      order: 5,
    },
    utteranceName: {
      order: 2,
    },
    switchButtonCol: {
      order: 1,
    }
  }
})
//   infoColor,
//   successColor,
//   warningColor,
//   dangerColor,
//   grayColor,
//   lightGrayColor
export default testsetsStyle
