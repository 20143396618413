import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import ListItem from 'components/List/ListItem/ListItem'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
// apollo
import { Query, Mutation, compose, graphql, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import ShowIcon from 'components/Icon/ShowIcon'
import Field from 'components/Form/OptionalField'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import QueryStatus from 'components/Info/QueryStatus'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ObjectChips from 'components/Chip/ObjectChips'
import { safeGetNamespaceFilteredList } from '../helper'
import FileBrowser from '../Settings/FileBrowser.jsx'
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable.jsx'
import LastChange from 'components/Info/LastChange'
import LinkButton from 'components/Button/LinkButton'
import MessageBox from 'components/Info/MessageBox'
import TestSessionProgress from '../TestSessions/TestSessionProgress.jsx'

import {
  renderTextField,
  renderTextArea,
  renderSelect,
  renderNamespaceField,
  required,
  url,
  composeValidators,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'

import testprojectsStyle from 'assets/jss/material-dashboard-react/views/testprojectsStyle.jsx'

import {
  FACTCHECKERPROJECT_QUERY,
  FACTCHECKERPROJECT_PROGRESS_QUERY,
  FACTCHECKERPROJECT_PROGRESS_SUBSCRIPTION,
  UPDATE_FACTCHECKERPROJECT,
  DELETE_FACTCHECKERPROJECT,
  FACTCHECKERINDEXSESSIONS_QUERY,
  FACTCHECKERINDEXSESSIONS_COUNT_QUERY,
  FACTCHECKERINDEXSESSION_PROGRESS_QUERY,
  FACTCHECKERINDEXSESSION_PROGRESS_SUBSCRIPTION,
  START_FACTCHECKERPROJECT,
  START_FACTCHECKER_CREATE_SAMPLES,
  START_FACTCHECKER_UPDATE_INDEX,
  RefetchFactCheckerProjectQueries,
  DeleteFactCheckerIndexListsFromCache,
  RefetchFactCheckerProjectIndexQueries
} from './gql'
import {
  DeleteTestSessionListsFromCache,
  RefetchTestSessionQueries
} from '../TestSessions/gql'
import { TESTSETS_DROPDOWN_QUERY } from '../TestSets/gql'
import { CHATBOTS_DROPDOWN_QUERY } from '../Chatbots/gql'

import TestSessionsEmbeddedTable from '../TestSessions/TestSessionsEmbeddedTable.jsx'

import { hasPermission, hasAnyPermission, canReadNamespace, canWriteNamespace } from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text.jsx'
import DropdownButton from 'components/Button/DropdownButton'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import Tooltip from 'components/Tooltip/Tooltip'

class FactCheckerProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newTestSessionCount: 0,
      newIndexSessionCount: 0,
      indexSessionText: null
    }
  }

  hasReadPermission() {
    return this.props.hasReadPermissions
  }
  hasWritePermission() {
    return this.props.hasWritePermissions
  }

  renderChatbotSelector() {
    const { chatbotsData } = this.props

    return <Field
      name="chatbot.id"
      component={renderSelect}
      label="Chatbot"
      data-unique="selFactCheckerProjectChatbotId"
      validate={required}
      disabled={!this.hasWritePermission()}
      loading={chatbotsData && chatbotsData.loading}
      error={chatbotsData && chatbotsData.error}
      items={chatbotsData && chatbotsData.chatbots && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace, this.props.factcheckerproject.chatbot ? this.props.factcheckerproject.chatbot.id : null).map(c => {
        return {
          key: c.id,
          chatbot: c
        }
      })}
    />
  }

  renderTestSetSelector() {
    const { testSetsData } = this.props

    return <Field
      name="testSet.id"
      component={renderSelect}
      label="Test Set"
      data-unique="selFactCheckerProjectTestSetId"
      validate={required}
      disabled={!this.hasWritePermission()}
      loading={testSetsData && testSetsData.loading}
      error={testSetsData && testSetsData.error}
      items={testSetsData && testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace, this.props.factcheckerproject.testSet ? this.props.factcheckerproject.testSet.id : null).map(t => {
        return {
          key: t.id,
          label: t.name,
          value: t
        }
      })}
    />
  }

  subscribeToIndexSessionProgress(id) {
    const { client, setAlertSuccessMessage } = this.props

    const subscription = client
      .subscribe({
        query: FACTCHECKERINDEXSESSION_PROGRESS_SUBSCRIPTION,
        variables: { id }
      })
      .subscribe({
        next(s) {
          if (s.data && s.data.factCheckerIndexSessionProgress && s.data.factCheckerIndexSessionProgress.status === 'READY') {
            setAlertSuccessMessage('FactCheck Ready for Use')
            subscription.unsubscribe()
          }
        }
      })
  }


  renderDashboard(factcheckerproject) {
    const { classes, user, license, setAlertErrorMessage, setAlertSuccessMessage } = this.props
    return (<>
      <GridContainer>
        <GridItem lg={6} grid>
          <ObjectChips lg={6} chatbot={factcheckerproject.chatbot} testSets={factcheckerproject.testSet ? [factcheckerproject.testSet] : []}/>
        </GridItem>
        <GridItem md={6} lg={3} grid>
          <TestSessionProgress
            query={FACTCHECKERPROJECT_PROGRESS_QUERY}
            querySelector={data => data.factcheckerlastindexsession}
            subscription={FACTCHECKERPROJECT_PROGRESS_SUBSCRIPTION}
            subscriptionSelector={data => data.factCheckerProjectProgress}
            testSession={{ id: factcheckerproject.id }}>

            {({ testSessionProgress: factCheckerProjectProgress }) => {
              if (factCheckerProjectProgress && factCheckerProjectProgress.status) {
                if (factCheckerProjectProgress.status === 'READY') {
                    return null
                } else if (factCheckerProjectProgress.status === 'FAILED') {


                  return <Tooltip title={factCheckerProjectProgress.err}>
                    <MessageBox
                      variant="failed"
                      title="Preparation failed"
                    link={<NavLink to={`/factcheck/projects/view/${factcheckerproject.id}/settings/files`}>Manage files</NavLink>}
                      iconComponent={<Text dangerBg icon={'exclamation'} > {factCheckerProjectProgress.status} </Text>}
                    />
                  </Tooltip>
                } else {
                  const title = factCheckerProjectProgress.status === 'EXTRACTING' ? 'Extracting text' :
                    factCheckerProjectProgress.status === 'INDEXING' ? 'Preparing text' :
                    factCheckerProjectProgress.status === 'SAMPLING' ? 'Generating queries' :
                    'Preparing text'

                  return <MessageBox
                    variant="process"
                    title={title}
                    iconComponent={<Text warningBg icon={'hourglass-start'} > {factCheckerProjectProgress.status} </Text>}
                  />
                }
              } else {
                return null
              }
            }}
          </TestSessionProgress >
        </GridItem>
        <GridItem md={6} lg={3} right largePaddingTop grid className={classes.dashboardbuttongrid}>
          <TestSessionProgress
              query={FACTCHECKERPROJECT_PROGRESS_QUERY}
              querySelector={data => data.factcheckerlastindexsession}
              subscription={FACTCHECKERPROJECT_PROGRESS_SUBSCRIPTION}
              subscriptionSelector={data => data.factCheckerProjectProgress}
              testSession={{ id: factcheckerproject.id }}>
              {({ testSessionProgress: factCheckerProjectProgress }) => (
                <Card noBorder cardWidthAuto>
                  <CardBody>
                    {this.hasWritePermission() && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                      <Mutation
                        mutation={START_FACTCHECKERPROJECT}
                        onCompleted={data => {
                          setAlertSuccessMessage('FactCheck Test session started ...')
                          this.setState({ newTestSessionCount: this.state.newTestSessionCount + 1 })
                        }}
                        onError={error => {
                          setAlertErrorMessage('FactCheck Test session failed', error)
                        }}
                        refetchQueries={[
                          ...RefetchTestSessionQueries()
                        ]}
                        update={DeleteTestSessionListsFromCache}
                      >
                        {(startFactCheckerProject, { loading, error }) =>
                        (<React.Fragment>
                          <Button leftRound
                            id="start_test_session"
                            disabled={!(factCheckerProjectProgress && factCheckerProjectProgress.status === 'READY')}
                            data-unique="btnFactCheckerProjectStartTestSession"
                            onClick={() => {
                              startFactCheckerProject({
                                variables: { id: factcheckerproject.id, debug: false },
                              })
                            }}
                          >
                            {factcheckerproject.lastFactCheckerSession && <><ShowIcon icon="redo" /> Repeat Test Session</>}
                            {!factcheckerproject.lastFactCheckerSession && <><ShowIcon icon="play-circle" /> Start Test Session</>}
                          </Button>
                          <DropdownButton
                            rightRound
                            disabled={!(factCheckerProjectProgress && factCheckerProjectProgress.status === 'READY') || !license.detailedReporting}
                            items={[{
                              id: 'start_test_session_logging',
                              name: 'Start FactCheck Test Session (Extended Logging) now',
                              icon: 'bug',
                              disabled: !(factCheckerProjectProgress && factCheckerProjectProgress.status === 'READY') || !license.detailedReporting,
                              dataUnique: 'btnFactCheckerProjectStartTestSessionDebug',
                              onClick: () => {
                                startFactCheckerProject({
                                  variables: { id: factcheckerproject.id, debug: true },
                                })
                              }
                            }]}
                            data-unique="ddbtnFactCheckerProjectStartTestSession"
                          />
                        </React.Fragment>
                        )}
                      </Mutation>
                    }
                  </CardBody>
                </Card>
              )}
          </TestSessionProgress>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={12}>
          <Card>
            <CardHeader color="info" >
              <Text header>Recent Test Results</Text>
              <Text subheader>Recent Test Results of this Test Project</Text>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} noPadding>
                  {this.renderTestResultsDashboard(factcheckerproject)}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </>
    )
  }

  render() {
    const { factcheckerproject, user } = this.props

    return (
      <CustomTabs
        name={`FactCheckerProjectTabs_${factcheckerproject.id}`}
        headerColor="info"
        tabs={[
          {
            tabName: 'Overview',
            tabIcon: <ShowIcon icon="infinity" />,
            disabled: !hasPermission(user, 'TESTSESSIONS_SELECT'),
            tabContent: hasPermission(user, 'TESTSESSIONS_SELECT') && this.renderDashboard(factcheckerproject),
            locationPrefix: `/factcheck/projects/view/${factcheckerproject.id}/dashboard`,
            dataUnique: 'tabFactCheckerProjectDashboard'
          },
          this.hasReadPermission() && hasAnyPermission(user, ['TESTPROJECTS_CREATE', 'TESTPROJECTS_UPDATE', 'TESTPROJECTS_DELETE', 'TESTSESSIONS_CREATE']) && {
            tabName: 'Configuration',
            tabRight: true,
            tabIcon: <ShowIcon icon="wrench" />,
            tabContent: this.renderSettingsForm(factcheckerproject),
            locationPrefix: `/factcheck/projects/view/${factcheckerproject.id}/settings`,
            dataUnique: 'tabFactCheckerProjectSettings'
          }
        ].filter(t => t)}
      />
    )
  }


  renderTestResultsDashboard(factcheckerproject) {
    const { classes } = this.props
    const tableName = `FactCheckerProject_Dashboard_TestSessions_${factcheckerproject.id}`

    return <GridContainer>
      <GridItem xs={12} className={classes.testSessionsEmbeddedTable}>
        <TestSessionsEmbeddedTable key={`TestSessions_${this.state.newTestSessionCount}`} variables={{ factCheckerSession: { project: { id: factcheckerproject.id } }, factCheckerTesting: true }} name={tableName} disableFilter hideProjectLink={true} />
      </GridItem>
    </GridContainer>
  }

  renderSettingsFormTab(factcheckerproject, fnForm, fnLeftButtons, fnRightButtons) {
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props

    return (<Mutation
      mutation={UPDATE_FACTCHECKERPROJECT}
      refetchQueries={[
        ...RefetchFactCheckerProjectQueries(),
      ]}
    >
      {(updateFactCheckerProject, { loading, error }) => (
        <Form
          mutators={{ ...arrayMutators }}
          onSubmit={async (values, form) => {
            const request = {
              variables: {
                id: values.id,
                factCheckerProject: {
                  name: values.name,
                  description: values.description || null,
                  namespace: values.namespace || null,
                  chatbotId: values.chatbot.id,
                  testSetId: values.testSet.id,
                  crawlerDepth: values.crawlerDepth,
                  urls: values.urls ? values.urls : []
                }
              }
            }
            try {
              const res = await updateFactCheckerProject(request)
              form.initialize(res.data.updateFactCheckerProject)
              setAlertSuccessMessage('FactCheck Project updated')
            } catch (error) {
              setAlertErrorMessage('FactCheck Project update failed', error)
            }
          }}
          initialValues={factcheckerproject}
          render={({
            handleSubmit,
            form,
            submitting,
            invalid,
            values
          }) => {
            return (<form onSubmit={handleSubmit}>
              <UnsavedFormSpy />
              <GridContainer>
                <GridItem md={12} lg={8}>
                  {fnForm({ form, values, submitting })}
                </GridItem>
                <GridItem md={12} lg={8} largePadding>
                  <FormActionsToolbar
                    leftButtons={<>
                      {fnLeftButtons && fnLeftButtons({ form, values, submitting })}
                    </>}
                    rightButtons={<>
                      {fnRightButtons && fnRightButtons({ form, values, submitting })}
                      {this.hasWritePermission() &&
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnFactCheckerProjectSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}
                          Save
                        </Button>
                      }
                    </>}
                  />
                </GridItem>
              </GridContainer>
            </form>
            )
          }
          }
        />
      )}
    </Mutation>)
  }

  renderSettingsForm(factcheckerproject) {
    const {
      setAlertSuccessMessage,
      setAlertErrorMessage,
      removeRecentListEntry,
      history,
      user,
    } = this.props


    return (<CustomTabsSecondary
      name={`tabFactCheckerProjectSettings_${factcheckerproject.id}`}
      headerColor="info"
      tabs={[
        {
          tabName: 'Test Project',
          tabContent: this.renderSettingsFormTab(factcheckerproject, () => <GridContainer>
            <GridItem md={6}>
              <Field
                name="name"
                component={renderTextField}
                label="Test Project Name"
                validate={required}
                disabled={!this.hasWritePermission()}
                data-unique="txtFactCheckerProjectName"
              />
            </GridItem>
            <GridItem md={6}>
              <Field
                name="namespace"
                component={renderNamespaceField}
                forWrite
                label="Namespace"
                disabled={!this.hasWritePermission()}
                data-unique="txtTFactCheckerProjectNamespace"
              />
            </GridItem>
            <GridItem md={6}>
              {this.renderChatbotSelector()}
            </GridItem>
            <GridItem md={6}>
              {this.renderTestSetSelector()}
            </GridItem>
            <GridItem md={6}>
              <Field
                name="indexNamespace"
                component={renderTextField}
                label="Index Name"
                validate={required}
                disabled
                data-unique="txtFactCheckerProjectIndexNamespace"
              />
            </GridItem>
            <GridItem md={12}>
              <Field
                name="description"
                component={renderTextArea}
                label="Test Project Description"
                rows={3}
                disabled={!this.hasWritePermission()}
                data-unique="txtFactCheckerProjectDescription"
              />
            </GridItem>
            <GridItem md={6}>
              <GridContainer>
                <FieldArray name="urls">
                  {({ fields }) =>
                    fields.map((name, index) => (<GridItem md={12} key={index}>
                      <Field
                        name={name}
                        component={renderTextField}
                        label={`Source of truth URL #${index + 1}`}
                        validate={composeValidators(required, url)}
                        disabled={!this.hasWritePermission()}
                        data-unique={`txtFactCheckerProjectURL_${index}`}
                        endAdornment={this.hasWritePermission() &&
                          <Button
                            aria-label="Delete"
                            justIcon
                            onClick={() => fields.remove(index)}
                            data-unique={`btnFactCheckerProjectURLRemove_${index}`}
                          >
                            <ShowIcon icon="trash" />
                          </Button>
                        }
                      />
                    </GridItem>))
                  }
                </FieldArray>
              </GridContainer>
            </GridItem>
            <GridItem md={6}>
              {/*
              <Field
                name="crawlerDepth"
                component={renderIntField}
                parse={parseInteger}
                label="Source of truth Crawler Maximum Depth"
                validate={composeValidators(minValue(0), maxValue(10))}
                disabled={!this.hasWritePermission()}
                data-unique="txtFactCheckerProjectCrawlerDepth"
                helperText="Follow links to other pages on the same domain"
                />*/}
            </GridItem>
          </GridContainer>,
          ({ form: { mutators: { push } }, submitting }) => this.hasWritePermission() && <>
            <Button data-unique="btnFactCheckerProjectURLAdd" secondary Border leftRound onClick={() => push('urls', '')} disabled={submitting}>
              <ShowIcon icon="plus" />
              Add Source of truth URL
            </Button>
          </>
          ),
          locationPrefix: `/factcheck/projects/view/${factcheckerproject.id}/settings/basic`,
          dataUnique: 'tabFactCheckerProjectSettings'
        },
        {
          tabName: 'Project Data',
          tabContent: (<GridContainer>
            <GridItem lg={12}>
              <Mutation
                mutation={START_FACTCHECKER_UPDATE_INDEX}
                onCompleted={data => {
                  setAlertSuccessMessage('FactCheck Indexing started ...')
                  this.setState({ newIndexSessionCount: this.state.newIndexSessionCount + 1 })
                  this.subscribeToIndexSessionProgress(data.updateFactCheckerProjectIndex.id)
                }}
                onError={error => {
                  setAlertErrorMessage('FactCheck Indexing failed', error)
                }}
                refetchQueries={[
                  ...RefetchFactCheckerProjectIndexQueries()
                ]}
                update={DeleteFactCheckerIndexListsFromCache}
              >
                {(startFactCheckerIndex, { loading, error }) =>
                  <FileBrowser disableAudioRecording expanded={true}
                    acceptUpload=".txt,.pdf,.html,.docx,.doc"
                    currentPath={factcheckerproject.documentBaseDir}
                    basePath={factcheckerproject.documentBaseDir}
                    onFileBrowserAction={({ action }) => {
                      startFactCheckerIndex({
                        variables: { id: factcheckerproject.id }
                      })
                    }}
                  />
                }
              </Mutation>
            </GridItem>
            <GridItem lg={12} right>
              {this.hasWritePermission() &&
                <Mutation
                  mutation={START_FACTCHECKER_UPDATE_INDEX}
                  onCompleted={data => {
                    setAlertSuccessMessage('FactCheck Indexing started ...')
                    this.setState({ newIndexSessionCount: this.state.newIndexSessionCount + 1 })
                    this.subscribeToIndexSessionProgress(data.updateFactCheckerProjectIndex.id)
                  }}
                  onError={error => {
                    setAlertErrorMessage('FactCheck Indexing failed', error)
                  }}
                  refetchQueries={[
                    ...RefetchFactCheckerProjectIndexQueries()
                  ]}
                  update={DeleteFactCheckerIndexListsFromCache}
                >
                  {(startFactCheckerIndex, { loading, error }) =>
                    <Button
                      smallTop
                      tertiary
                      id="start_test_session"
                      data-unique="btnFactCheckerProjectStartIndex"
                      onClick={() => {
                        startFactCheckerIndex({
                          variables: { id: factcheckerproject.id },
                        })
                      }}
                    >
                      <ShowIcon icon="play-circle" /> Update Document Index
                    </Button>
                  }
                </Mutation>
              }
              {this.hasWritePermission() &&
                <Mutation
                  mutation={START_FACTCHECKER_CREATE_SAMPLES}
                  onCompleted={data => {
                    setAlertSuccessMessage('FactCheck Sampling started ...')
                    this.setState({ newIndexSessionCount: this.state.newIndexSessionCount + 1 })
                    this.subscribeToIndexSessionProgress(data.createFactCheckerSampleQueries.id)
                  }}
                  onError={error => {
                    setAlertErrorMessage('FactCheck Sampling failed', error)
                  }}
                  refetchQueries={[
                    ...RefetchFactCheckerProjectIndexQueries()
                  ]}
                  update={DeleteFactCheckerIndexListsFromCache}
                >
                  {(startFactCheckerSampling, { loading, error }) =>
                    <Button
                      smallTop
                      tertiary
                      id="start_test_session"
                      data-unique="btnFactCheckerProjectStartSampling"
                      onClick={() => {
                        startFactCheckerSampling({
                          variables: { id: factcheckerproject.id },
                        })
                      }}
                    >
                      <ShowIcon icon="play-circle" /> Create Sample Queries from Documents
                    </Button>
                  }
                </Mutation>
              }
            </GridItem>
            <GridItem lg={12}>
              <ServerSidePagingTable
                key={`FactCheckerIndexSession_${factcheckerproject.id}_${this.state.newIndexSessionCount}`}
                name={`FactCheckerIndexSession_${factcheckerproject.id}`}

                gqlQuery={{
                  query: FACTCHECKERINDEXSESSIONS_QUERY,
                  notifyOnNetworkStatusChange: true
                }}
                gqlCountQuery={{
                  query: FACTCHECKERINDEXSESSIONS_COUNT_QUERY,
                  countValue: data => data.factcheckerindexsessionsCount
                }}
                disableFilter
                disableOrderBy
                disablePageSize
                pageSize={5}
                gqlVariables={() => ({ id: factcheckerproject.id })}
                tableHeaderColor="primary"
                tableHead={[
                  'Started',
                  'Extract Text',
                  'Update Index',
                  'Sample Queries',
                  'Status',
                  ''
                ]}

                tableData={data =>
                  data.factcheckerindexsessions &&
                  data.factcheckerindexsessions.map(t => [
                    () => <LastChange {...t} />,
                    () => !!t.extract ? <ShowIcon custom icon="success" /> : '' ,
                    () => !!t.index ? <ShowIcon custom icon="success" /> : '' ,
                    () => t.sample ? <ShowIcon custom icon="success" /> : '',
                    () => <TestSessionProgress
                      query={FACTCHECKERINDEXSESSION_PROGRESS_QUERY}
                      querySelector={data => data.factcheckerindexsession}
                      subscription={FACTCHECKERINDEXSESSION_PROGRESS_SUBSCRIPTION}
                      subscriptionSelector={data => data.factCheckerIndexSessionProgress}
                      testSession={{ id: t.id }}>
                        {({ testSessionProgress: indexSession }) =>
                          <Text
                            successBg={indexSession.status === 'READY'}
                            dangerBg={indexSession.status === 'FAILED'}
                            warningBg={indexSession.status === 'PENDING' || indexSession.status === 'EXTRACTING'  || indexSession.status === 'INDEXING'  || indexSession.status === 'SAMPLING'}
                            icon={
                              indexSession.status === 'READY' ? 'check' : '' ||
                              indexSession.status === 'FAILED' ? 'exclamation' : '' ||
                              indexSession.status === 'PENDING' ? 'hourglass-half' : '' ||
                              indexSession.status === 'EXTRACTING' ? 'hourglass-half' : ''  ||
                              indexSession.status === 'INDEXING' ? 'hourglass-half' : ''  ||
                              indexSession.status === 'SAMPLING' ? 'hourglass-half' : ''
                            }>
                            {indexSession.status}
                          </Text>
                        }
                      </TestSessionProgress>,
                    () => <TestSessionProgress
                      query={FACTCHECKERINDEXSESSION_PROGRESS_QUERY}
                      querySelector={data => data.factcheckerindexsession}
                      subscription={FACTCHECKERINDEXSESSION_PROGRESS_SUBSCRIPTION}
                      subscriptionSelector={data => data.factCheckerIndexSessionProgress}
                      testSession={{ id: t.id }}>
                        {({ testSessionProgress: indexSession }) =>
                          <>
                            <LinkButton onClick={() => this.setState({ indexSessionText: indexSession.log })}>Show Log</LinkButton>
                            {indexSession.err && <> | <LinkButton onClick={() => this.setState({ indexSessionText: indexSession.err })}>Show Err</LinkButton></>}
                          </>
                        }
                      </TestSessionProgress>,
                  ])}
              />
              <ConfirmationDialog
                open={!!this.state.indexSessionText}
                onOk={() => this.setState({ indexSessionText: '' })}
                title="FactCheck Index Session"
              >
                <Text pre>{this.state.indexSessionText || ''}</Text>
              </ConfirmationDialog>
            </GridItem>
          </GridContainer>
          ),
          locationPrefix: `/factcheck/projects/view/${factcheckerproject.id}/settings/files`,
          dataUnique: 'tabFactCheckerProjectSettingsFiles'
        },
        {
          tabName: 'Danger Zone',
          tabContent: <GridContainer>
            <GridItem md={8} lg={4}>
              <ListItem>
                <Text danger lg padding><ShowIcon icon="trash" /></Text>
                <GridContainer>
                  <GridItem xs={12}>
                    <Text bold>Delete FactCheck Project</Text>
                  </GridItem>
                  <GridItem xs={12}>
                    <Text>Remove this FactCheck Project and its configuration settings</Text>
                  </GridItem>
                </GridContainer>
                <Mutation
                  mutation={DELETE_FACTCHECKERPROJECT}
                  onCompleted={data => {
                    removeRecentListEntry({
                      url: `/factcheck/projects/view/${factcheckerproject.id}`
                    })
                    setAlertSuccessMessage('FactCheck Project deleted')
                    history.push('/factcheck')
                  }}
                  onError={error => {
                    setAlertErrorMessage(
                      'FactCheck Project deletion failed',
                      error,
                    )
                  }}
                  refetchQueries={[
                    ...RefetchFactCheckerProjectQueries()
                  ]}
                >
                  {(deleteFactCheckerProject, { loading, error }) => (
                    <ConfirmationButton
                      confirmationText={`When deleting the FactCheck Project "${factcheckerproject.name}", all configuration settings are removed. Test Results won't be deleted. Are you sure you want to delete it ?`}
                      requireCheck={true}
                      danger
                      small
                      minWidth
                      disabled={!hasPermission(user, 'TESTPROJECTS_DELETE')}
                      onClick={() => {
                        deleteFactCheckerProject({
                          variables: { id: factcheckerproject.id },
                        })
                      }}
                      data-unique="btnFactCheckerProjectDelete"
                    >
                      Delete
                    </ConfirmationButton>
                  )}
                </Mutation>
              </ListItem>
            </GridItem>
          </GridContainer>,
          locationPrefix: `/factcheck/projects/view/${factcheckerproject.id}/settings/danger`,
          dataUnique: 'tabFactCheckerProjectSettingsDanger'
        }
      ]}
    />)
  }
}



const FactCheckerProjectComponent = compose(
  withApollo,
  withStyles(
    (theme) => ({
      ...testprojectsStyle(theme),
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace }),
    { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
  ),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
  }),
  graphql(TESTSETS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      testSetsData: data,
    }),
  })
)(FactCheckerProject)

export default connect(state => ({ user: state.token.user }))(function ({ match, user, ...rest }) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        {match.params && match.params.projectId && (
          <Query query={FACTCHECKERPROJECT_QUERY} variables={{ id: match.params.projectId }}>
            {(queryResult) => <QueryStatus {...queryResult} query="factcheckerproject" card>{(data) => {
              return <FactCheckerProjectComponent
                match={match}
                factcheckerproject={data.factcheckerproject}
                hasReadPermissions={hasPermission(user, 'TESTPROJECTS_SELECT') && canReadNamespace(user, user.namespacePermissions, data.factcheckerproject.namespace)}
                hasWritePermissions={hasAnyPermission(user, ['TESTPROJECTS_CREATE', 'TESTPROJECTS_UPDATE']) && canWriteNamespace(user, user.namespacePermissions, data.factcheckerproject.namespace)}
                {...rest} />
            }}</QueryStatus>}
          </Query>
        )}
      </GridItem>
    </GridContainer>
  )
})
