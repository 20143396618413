import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from 'components/Tooltip/Tooltip'
import List from '@material-ui/core/List'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from 'components/List/ListItem/ListItemIcon'
import ListItemText from 'components/List/ListItem/ListItemText'
// apollo
import { Query, Mutation, graphql, compose } from 'react-apollo'
// core components
import DropdownButton from 'components/Button/DropdownButton'
import Table from 'components/Table/AdvancedTable.jsx'
import ObjectChips from 'components/Chip/ObjectChips'
import Button from 'components/Button/Button'
import LinkButton from 'components/Button/LinkButton'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import DateFormat from 'components/Info/DateFormat'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import { renderOverallStatBarAndLink } from '../TrainerSessions/helper'
import ErrorFormat from 'components/Info/ErrorFormat'
import QueryStatus from 'components/Info/QueryStatus'
import ShowIcon from 'components/Icon/ShowIcon'
import FeatureUpgradeNavLink from 'components/FeatureUpgrade/FeatureUpgradeNavLink'
import { ConnectorsWithUI } from 'components/Capability/ConnectorUIs'

import { downloadfileformpost } from 'helper/downloadHelper'
import { getRootPath } from './helper'

import config from 'config'

import TestSessionProgress from './TestSessionProgress.jsx'
import TestSessionTestCases, { filterTypes } from './TestSessionTestCases.jsx'
import { renderTrending, renderTrendingColor, renderTrendingLabel } from '../Coach/Dashboard/helper'
import {formatMillisecondsDuration, renderTrendingDurationLabel} from '../helper'

import imgJson from 'assets/img/download-files/json.svg'
import imgCsv from 'assets/img/download-files/csv.svg'
import imgXml from 'assets/img/download-files/xml.svg'
import imgPdf from 'assets/img/download-files/pdf.svg'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'

import {
  TESTSESSION_QUERY,
  DELETE_TESTSESSION,
  CANCEL_TESTSESSION,
  RETRY_TESTSESSION,
  TESTSESSIONJOB_LOGS_QUERY,
  TESTSESSION_RESULTS_QUERY,
  TESTSESSION_RESULTS_SUBSCRIPTION,
  TESTSESSIONTREND_QUERY,
  DeleteTestSessionListsFromCache,
  RefetchTestSessionQueries
} from './gql'

import {
  START_TESTPROJECT,
  RefetchTestProjectQueriesOnNewTestSession
} from '../TestProjects/gql'

import { hasPermission, hasAnyPermission, canReadNamespace, canWriteNamespace } from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import AdvancedTable from 'components/Table/AdvancedTable'
import NumberFormat from 'react-number-format'
import Divider from 'components/Divider/Divider'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { START_FACTCHECKERPROJECT } from 'views/LLMprojects/gql'

class TestSession extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testcaseProps: null,
      jobOpenId: null,
      testSessionProgressKey: 0
    }
  }

  hasReadPermission() {
    return this.props.hasReadPermissions
  }
  hasWritePermission() {
    return this.props.hasWritePermissions
  }

  renderDashboard(testsession, loading) {
    const { history, user, license, classes } = this.props
    const { mutateStartFactCheckerProject, mutateStartTestProject, mutateRetryTestSession } = this.props

    const goToTestCase = (id, name) => {
      this.setState({
        testcaseProps: {
          filter: {
            showSucceeded: true,
            showFailed: true,
            filterType: filterTypes.TEST_CASENAME,
            filterText: name,
            page: 0
          }
        }
      })
      history.push(`${this.getRootPath('testsession')}/testcases`)

    }

    const renderAggregatedResultsCard = (aggregatedResults) => {

      return aggregatedResults && aggregatedResults.length > 0 && <GridItem xs={12}><ExpansionPanel>
        <ExpansionPanelSummary>
          <Text header>{aggregatedResults.filter(r => !r.success).length > 0 && <ShowIcon custom icon="error" />} Test Metrics</Text>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <GridContainer nounset>
            <GridItem xs={12}>
              <GridContainer>
                {aggregatedResults.map((r, i) => {
                  return <GridItem key={i} xs={2} grid>
                    <Card>
                      <CardBody>
                        <Text bold>{r.label}</Text>
                        {r.success ? <Text header success><NumberFormat value={r.aggregatedValue} displayType={'text'} decimalScale={4} /></Text> : <Text header danger>{r.err}</Text>}
                      </CardBody>
                    </Card>

                  </GridItem>

                })}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </GridItem>

    }

    return (<GridContainer>
      {this.hasReadPermission() &&
      <TestSessionProgress testSession={testsession} key={this.state.testSessionProgressKey}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
          const chartData = testSessionProgress.chartData && JSON.parse(testSessionProgress.chartData)
          let expectedTotalTestCaseCount = 0
          if (chartData) {
            expectedTotalTestCaseCount = chartData.expectedTotalTestCaseCount || chartData.successCount + chartData.failedCount
          }
          return (
            <React.Fragment>
              <GridItem lg={12} right middle smallPadding smallMarginRight>
                {testsession.id && testsession.testProject && <React.Fragment>
                  <Text primary>Recent Test Sessions </Text><Text paddingLeftRight>  |  </Text><Text primary><DateFormat seconds>{testsession.createdAt}</DateFormat></Text>
                  <Query query={TESTSESSIONTREND_QUERY} variables={{ id: testsession.id }} fetchPolicy={'network-only'}>
                    {({ data }) => (<React.Fragment>
                      <Tooltip title="View Previous Test Session">
                        <Button justIcon Border
                          disabled={!data || !data.testsessiontrend || !data.testsessiontrend.previousTestSessionId}
                          onClick={async () => {
                            history.push(`${this.getRootPath('testproject')}/results/${data.testsessiontrend.previousTestSessionId}/dashboard`)
                          }}
                        >
                          <ShowIcon icon="angle-left" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="View Next Test Session">
                        <Button justIcon Border
                          disabled={!data || !data.testsessiontrend || !data.testsessiontrend.nextTestSessionId}
                          onClick={async () => {
                            history.push(`${this.getRootPath('testproject')}/results/${data.testsessiontrend.nextTestSessionId}/dashboard`)

                          }}
                        >
                          <ShowIcon icon="angle-right" />
                        </Button>
                      </Tooltip>
                    </React.Fragment>)}
                  </Query>
                </React.Fragment>
                }
                <GridItem smallMarginRight ></GridItem>
                {hasPermission(user, 'TESTSESSIONS_CREATE') && this.hasWritePermission() &&
                  <TestSessionProgress testSession={testsession} key={this.state.testSessionProgressKey}>
                    {({ testSessionProgress }) => {
                      const repeatItems = []
                      if (testsession.testProject && testsession.testProject.id && license.detailedReporting) {
                        repeatItems.push({
                          id: 'repeatTestSessionDebug',
                          name: 'Repeat Full Test Session (Extended Logging)',
                          dataUnique: 'ddbtniRepeatLog',
                          disabled: testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED',
                          icon: 'bug',
                          onClick: () => {
                            mutateStartTestProject({
                              variables: { id: testsession.testProject.id, debug: true }
                            })
                          }
                        })
                      }
                      if (testSessionProgress.status !== 'READY') {
                        repeatItems.push({
                          id: 'retryTestSession',
                          name: 'Retry Failed Test Cases',
                          dataUnique: 'ddbtniRepeatFailed',
                          disabled: testSessionProgress.status !== 'FAILED',
                          icon: 'redo',
                          onClick: async () => {
                            await mutateRetryTestSession({
                              variables: { id: testsession.id, allTestCases: false },
                            })
                            this.setState({ testSessionProgressKey: this.state.testSessionProgressKey + 1 })
                          }
                        })
                      }
                      if (testsession.factCheckerSession && testsession.factCheckerSession.project && license.detailedReporting) {
                        repeatItems.push({
                          id: 'repeatTestSessionDebug',
                          name: 'Repeat Full Test Session (Extended Logging)',
                          dataUnique: 'ddbtniRepeatLog',
                          disabled: testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED',
                          icon: 'bug',
                          onClick: () => {
                            mutateStartFactCheckerProject({
                              variables: { id: testsession.factCheckerSession.project.id, debug: true }
                            })
                          }
                        })
                      }
                      return (<>{((testsession.testProject && testsession.testProject.id) || (testsession.factCheckerSession && testsession.factCheckerSession.project)) && <>
                        <Button leftRound
                          id="repeatTestSession"
                          data-unique="btnRepeatFullTestSession"
                          disabled={(testsession.testProject && !testsession.testProject.id) || (testsession.factCheckerSession && testsession.factCheckerSession.project) || (testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED')}
                          icon="play-circle"
                          onClick={() => {
                            if (testsession.testProject && testsession.testProject.id) {
                              mutateStartTestProject({
                                variables: { id: testsession.testProject.id, debug: false }
                              })
                            }
                            if (testsession.factCheckerSession && testsession.factCheckerSession.project) {
                              mutateStartFactCheckerProject({
                                variables: { id: testsession.factCheckerSession.project.id, debug: false }
                              })
                            }
                          }
                          }
                        ><ShowIcon icon="redo" /> Repeat Test Session</Button>
                        <DropdownButton
                          rightRound
                          data-unique="ddbtnRepeatTestSession"
                          disabled={repeatItems.length === 0 || (testsession.factCheckerSession && testsession.factCheckerSession.project) || (testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED'  && testSessionProgress.status !== 'CANCELLED')}
                          items={repeatItems}
                        ></DropdownButton></>}
                      </>
                      )
                    }}
                  </TestSessionProgress>
                }
              </GridItem>
              <GridItem xs={12} grid>
                <Query
                  query={TESTSESSIONTREND_QUERY}
                  variables={{ id: testsession.id }}
                  fetchPolicy={'network-only'}>
                  {({ loading, error, data }) => {
                    if (error) {
                      return <ErrorFormat err={error} />
                    }
                    if (!data || !data.testsessiontrend) return null
                    const trend = data.testsessiontrend

                    return (<React.Fragment>
                      <GridContainer border borderRadius noMargin>
                        <GridItem lg borderRight noPadding grid widthPercent>
                          <Card noBorder noMarginBottom noMarginTop hoverlight borderRadiusRight>
                            <CardBody
                              LargePadding
                              tabIndex={0}
                              className={classes.cardLink}
                              data-unique="btnTestSessionChartDataTotalCount"
                              onClick={() => {
                                this.setState({
                                  testcaseProps: {
                                    filter: {
                                      showSucceeded: true,
                                      showFailed: true,
                                      filterText: '',
                                      page: 0
                                    }
                                  }
                                })
                                history.push(`${this.getRootPath('testproject')}/results/${testsession.id}/testcases`)
                              }}
                            >
                              <GridContainer fullWidth paddingLeft>
                                <GridItem lg={12} middle noPaddingLeft><Text regular>Total</Text></GridItem>
                                <GridItem lg={12} middle className={classes.textLeftBorderDefault}>
                                  <Text mlg bold data-unique="txtTestSessionChartDataTotalCountNumber">{!chartData ? (isDone ? '0' : '?') : expectedTotalTestCaseCount}</Text>
                                </GridItem>
                                <GridItem lg={12} middle noPaddingLeft largePaddingTop>
                                  {chartData && <Text regular data-unique="txtTestSessionChartDataTotalCountHelperText">{isDone
                                    ? `${chartData.successCount + chartData.failedCount} processed / ${expectedTotalTestCaseCount - (chartData.successCount + chartData.failedCount)} skipped`
                                    : `${chartData.successCount + chartData.failedCount} processed`}</Text>}
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem lg borderRight noPadding grid widthPercent>
                          <Card noBorder noMarginBottom  noMarginTop hoverlight noBorderRadius>
                            <CardBody
                              LargePadding
                              tabIndex={0}
                              className={classes.cardLink}
                              data-unique="btnTestSessionChartDataTestCasesSucceeded"
                              onClick={() => {
                                this.setState({
                                  testcaseProps: {
                                    filter: {
                                      showSucceeded: true,
                                      showFailed: false,
                                      filterText: '',
                                      page: 0
                                    }
                                  }
                                })
                                history.push(`${this.getRootPath('testsession')}/testcases`)

                              }}
                            >
                              <GridContainer fullWidth paddingLeft>
                                <GridItem lg={12} middle noPaddingLeft><Text regular>Succeeded</Text></GridItem>
                                <GridItem lg={12} middle className={classes.textLeftBorderGreen}>
                                  <Text mlg bold data-unique="btnTestSessionChartDataTestCasesSucceededNumber">{!chartData ? (isDone ? '0' : '?') : chartData.successCount}</Text>
                                </GridItem>
                                <GridItem lg={12} middle noPaddingLeft largePaddingTop>
                                  {chartData && <React.Fragment>
                                    {renderTrending(trend.testSessionSuccessCount, false, trend.previousTestSessionSuccessCount)}
                                    {trend.previousTestSessionId && renderTrendingColor(<NavLink tabIndex={-1} className={classes.trendnumberpos} to={`${this.getRootPath('testproject')}/results/${trend.previousTestSessionId}`}>{renderTrendingLabel(trend.testSessionSuccessCount - trend.previousTestSessionSuccessCount)}</NavLink>, trend.testSessionSuccessCount)}
                                  </React.Fragment>}
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem lg borderRight noPadding grid widthPercentWithoutBorder>
                          <Card noBorder noMarginBottom  noMarginTop hoverlight noBorderRadius>
                            <CardBody
                              LargePadding
                              tabIndex={0}
                              className={classes.cardLink}
                              data-unique="btnTestSessionChartDataTestCasesFailed"
                              onClick={() => {
                                this.setState({
                                  testcaseProps: {
                                    filter: {
                                      showSucceeded: false,
                                      showFailed: true,
                                      filterText: '',
                                      page: 0
                                    }
                                  }
                                })
                                history.push(`${this.getRootPath('testsession')}/testcases`)

                              }}
                            >
                              <GridContainer fullWidth paddingLeft>
                                <GridItem lg={12} middle noPaddingLeft><Text regular>Failed</Text></GridItem>
                                <GridItem lg={12} middle className={classes.textLeftBorderRed}>
                                  <Text mlg bold data-unique="btnTestSessionChartDataTestCasesFailedNumber">{!chartData ? (isDone ? '0' : '?') : chartData.failedCount}</Text>
                                </GridItem>
                                <GridItem lg={12} middle noPaddingLeft largePaddingTop>
                                  {chartData && <React.Fragment>
                                    {renderTrending(trend.testSessionFailedCount, true, trend.previousTestSessionFailedCount)}
                                    {trend.previousTestSessionId && renderTrendingColor(<NavLink tabIndex={-1} className={classes.trendnumberpos} to={`${this.getRootPath('testproject')}/results/${trend.previousTestSessionId}`}>{renderTrendingLabel(trend.testSessionFailedCount - trend.previousTestSessionFailedCount)}</NavLink>, trend.previousTestSessionFailedCount)}
                                  </React.Fragment>}
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem lg borderRight widthPercent>
                          <Card noBorder noMarginBottom  noMarginTop>
                            <CardBody
                              LargePadding
                              tabIndex={0}
                              data-unique="btnTestSessionChartDataTestCasesAvgDuration"
                            >
                              <GridContainer fullWidth>
                                <GridItem lg={12} middle noPaddingLeft><Text regular>Average Duration</Text></GridItem>
                                <GridItem lg={12} middle className={classes.textLeftBorderYellow}>
                                  <Text mlg bold>{!chartData ? (isDone ? '0' : '?') : formatMillisecondsDuration(trend.testSessionAverageDuration)}</Text>
                                </GridItem>
                                <GridItem lg={12} middle noPaddingLeft largePaddingTop>
                                  {chartData && <React.Fragment>
                                    {renderTrending(trend.testSessionAverageDuration, true, trend.previousTestSessionAverageDuration)}
                                        {trend.previousTestSessionId && renderTrendingColor(<span tabIndex={-1} className={classes.trendnumberpos}>{renderTrendingDurationLabel(trend.testSessionAverageDuration - trend.previousTestSessionAverageDuration)}</span>, trend.testSessionAverageDuration)}
                                  </React.Fragment>}
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem lg borderRight widthPercent>
                          <Card noBorder noMarginBottom  noMarginTop>
                            <CardBody
                              LargePadding
                              tabIndex={0}
                              data-unique="btnTestSessionChartDataTestCasesTotalDuration"
                            >
                              <GridContainer fullWidth>
                                <GridItem lg={12} middle noPaddingLeft><Text regular>Agent Duration</Text></GridItem>
                                <GridItem lg={12} middle className={classes.textLeftBorderYellow}>
                                  <Text mlg bold>{!chartData ? (isDone ? '0' : '?') : formatMillisecondsDuration(trend.testSessionTotalDuration)}</Text>
                                </GridItem>
                                <GridItem lg={12} middle noPaddingLeft largePaddingTop>
                                  {chartData && <React.Fragment>
                                    {renderTrending(trend.testSessionTotalDuration, true, trend.previousTestSessionTotalDuration)}
                                        {trend.previousTestSessionId && renderTrendingColor(<span tabIndex={-1} className={classes.trendnumberpos}>{renderTrendingDurationLabel(trend.testSessionTotalDuration - trend.previousTestSessionTotalDuration)}</span>, trend.testSessionTotalDuration)}
                                      </React.Fragment>}
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                        <GridItem lg borderRight widthPercentWithoutBorder>
                          <Card noBorder noMarginBottom  noMarginTop>
                            <CardBody
                              LargePadding
                              tabIndex={0}
                              data-unique="btnTestSessionChartDataTestCasesRunTimeDuration"
                            >
                              <GridContainer fullWidth>
                                <GridItem lg={12} middle noPaddingLeft><Text regular>Run Time Duration</Text></GridItem>
                                <GridItem lg={12} middle className={classes.textLeftBorderYellow}>
                                  <Text mlg bold>{!chartData ? (isDone ? '0' : '?') : formatMillisecondsDuration(trend.testSessionRunTimeDuration)}</Text>
                                </GridItem>
                                <GridItem lg={12} middle noPaddingLeft largePaddingTop>
                                  {chartData && <React.Fragment>
                                    {renderTrending(trend.testSessionRunTimeDuration, true, trend.previousTestSessionRunTimeDuration)}
                                    {trend.previousTestSessionId && renderTrendingColor(<span tabIndex={-1} className={classes.trendnumberpos}>{renderTrendingDurationLabel(trend.testSessionRunTimeDuration - trend.previousTestSessionRunTimeDuration)}</span>, trend.testSessionRunTimeDuration)}
                                  </React.Fragment>}
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                        {false && !_.isNil(trend.testSessionNLPScore) &&
                          <GridItem xs={2}>
                            <Card>
                              <CardBody>
                                {renderTrending(trend.testSessionNLPScore, false, trend.previousTestSessionNLPScore)}
                                <Text important>
                                  NLP Score: {renderOverallStatBarAndLink(testsession, trend.testSessionNLPScore, true)} {trend.previousTestSessionId && <NavLink to={`${this.getRootPath('testproject')}/results/${trend.previousTestSessionId}`}>{`(${renderTrendingLabel(trend.testSessionNLPScore - trend.previousTestSessionNLPScore)})`}</NavLink>}
                                </Text>
                              </CardBody>
                            </Card>

                          </GridItem>
                        }
                      </GridContainer>
                    </React.Fragment>)
                  }}
                </Query>
              </GridItem>
              <GridItem xs={12}>
                <ObjectChips rootPath={getRootPath(this.props.location, 1)} dataUniquePrefix="btnTestSession" testProject={testsession.testProject} factCheckerProject={testsession.factCheckerSession?.project?.id} chatbot={testsession.chatbot} testSets={testsession.testSets} deviceSets={testsession.deviceSets} />
              </GridItem>
              {renderAggregatedResultsCard(testSessionProgress.aggregatedResults)}
            </React.Fragment>
          )
        }}
      </TestSessionProgress>}

      {this.hasReadPermission() && <>
      <GridItem xs={12}>
        <TestSessionProgress testSession={testsession} key={this.state.testSessionProgressKey}>
          {({ testSessionProgress }) => {
            if (!testSessionProgress) return null
            const chartData = testSessionProgress.chartData && JSON.parse(testSessionProgress.chartData)
            const renderJobFailures = (jobs) => {
              const jobErrors = (jobs && _.uniq(jobs.filter(j => j.status === 'FAILED' && j.err).map(j => j.err))) || []
              if (testSessionProgress.trainerSession && testSessionProgress.trainerSession.status === 'FAILED' && testSessionProgress.trainerSession.err) {
                jobErrors.push(testSessionProgress.trainerSession.err)
              }
              if (jobErrors && jobErrors.length > 0 && (!chartData || (chartData && (chartData.failedCount > 0 || chartData.totalCount === 0)))) {
                return <Card>
                  <CardHeader>
                    <Text header horizontalCenter><ShowIcon custom moveleft icon="error" /> Failure Alerts</Text>
                  </CardHeader>
                  <CardBody>
                    <GridContainer data-unique="txtTestSession_FAILED">
                      {jobErrors.map((err, index) =>
                        <GridItem xs={12} key={index}>
                          <ErrorFormat err={err} suppress split />
                        </GridItem>
                      )}
                    </GridContainer>
                  </CardBody>
                </Card>
              }
            }

            const renderRecovered = (recovered) => <Card>
              <CardHeader color="info">
                <Text header>Recovered Test Cases</Text>
                <Text subheader>Successful Test Cases compared to Previous Test Execution</Text>
              </CardHeader>
              <CardBody noPadding>
                {!_.isNil(recovered) && recovered.length > 0 &&
                  <Table
                    name={`TestSessionDashboard_${testsession.id}_Recovered`}
                    tableHeaderColor="primary"
                    disableHeader
                    disablePageSize
                    tableHead={[
                      'Test Case',
                      ''
                    ]}
                    tableData={recovered && recovered.map(r => [
                      () => <LinkButton onClick={() => goToTestCase(r.id, r.testcaseName)}>{r.testcaseName}</LinkButton>,
                      () => <ShowIcon custom icon="success" />
                    ])
                    }
                  />
                }
                {!_.isNil(recovered) && recovered.length === 0 && <Text primary padding>No recovered Test Cases</Text>}
                {_.isNil(recovered) && <LoadingIndicator box large />}
              </CardBody>
            </Card>

            const renderFailed = (failed) => <Card>
              <CardHeader color="info">
                <Text header>Injected Defects</Text>
                <Text subheader>Failing Test Cases compared to Previous Test Execution</Text>
              </CardHeader>
              <CardBody noPadding>
                {!_.isNil(failed) && failed.length > 0 &&
                  <Table
                    name={`TestSessionDashboard_${testsession.id}_Failed`}
                    tableHeaderColor="primary"
                    disableHeader
                    disablePageSize
                    tableHead={[
                      'Test Case',
                      '',
                    ]}
                    tableData={failed && failed.map(r => [
                      () => <LinkButton onClick={() => goToTestCase(r.id, r.testcaseName)}>{r.testcaseName}</LinkButton>,
                      () => <ShowIcon custom icon="error" />
                    ])
                    }
                  />
                }
                {!_.isNil(failed) && failed.length === 0 && <Text primary padding>No defects injected</Text>}
                {_.isNil(failed) && <LoadingIndicator box large />}
              </CardBody>
            </Card>

            if (testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED') {
              return <GridContainer>
                <GridItem xs={12}>
                  {renderJobFailures(testSessionProgress.jobs)}
                </GridItem>
                <GridItem xs={12} sm={6}>
                  {renderRecovered()}
                </GridItem>
                <GridItem xs={12} sm={6}>
                  {renderFailed()}
                </GridItem>
              </GridContainer>
            } else {
              return <Query
                query={TESTSESSIONTREND_QUERY}
                variables={{ id: testsession.id }}
                fetchPolicy={'network-only'}>
                {({ loading, error, data }) => {
                  if (error) {
                    return <ErrorFormat err={error} />
                  }
                  if (!data || !data.testsessiontrend) return null
                  const trend = data.testsessiontrend

                  const recovered = (trend.testResultsStatusChanged && trend.testResultsStatusChanged.filter(r => r.success)) || []
                  const failed = (trend.testResultsStatusChanged && trend.testResultsStatusChanged.filter(r => !r.success)) || []

                  return (
                    <GridContainer>
                      <GridItem xs={12}>
                        {renderJobFailures(testSessionProgress.jobs)}
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        {renderRecovered(recovered)}
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        {renderFailed(failed)}
                      </GridItem>
                    </GridContainer>
                  )
                }}
              </Query>
            }
          }}
        </TestSessionProgress>
      </GridItem>
      <GridItem xs={12} sm={6}>
        <TestSessionProgress
          query={TESTSESSION_RESULTS_QUERY}
          querySelector={data => data.testsessiontestcaseresults}
          subscription={TESTSESSION_RESULTS_SUBSCRIPTION}
          subscriptionSelector={data => data.testSessionProgressResults}
          variables={{
            where: {}, orderBy: 'duration_ASC', skip: 0, first: 10
          }}
          testSession={testsession}>
          {({ testSessionProgress: testSessionResults }) => {
            if (!testSessionResults || !_.isArray(testSessionResults)) return null
            return (<Card>
              <CardHeader color="info">
                <Text header>Quickest Test Cases</Text>
                <Text subheader>Top 10 Test Cases with quickest execution time</Text>
              </CardHeader>
              <CardBody noPadding>
                <Table
                  name={`TestSessionDashboard_${testsession.id}_Quickest`}
                  disableHeader={true}
                  disableFooter={true}
                  tableHeaderColor="primary"
                  tableHead={[
                    '',
                    'Test Case',
                    'Duration'
                  ]}
                  pageLoading={false}
                  tableData={testSessionResults.map(r => [
                    () => r.success ? (<Text success><ShowIcon custom icon="success" /></Text>) : (<Text danger><ShowIcon custom icon="error" /></Text>),
                    () => <LinkButton gray onClick={() => goToTestCase(r.id, r.testcaseName)}>{r.testcaseName}</LinkButton>,
                    `${r.duration}ms`
                  ])
                  }
                />
              </CardBody>
            </Card>)
          }}
        </TestSessionProgress>
      </GridItem>
      <GridItem xs={12} sm={6}>
        <TestSessionProgress
          query={TESTSESSION_RESULTS_QUERY}
          querySelector={data => data.testsessiontestcaseresults}
          subscription={TESTSESSION_RESULTS_SUBSCRIPTION}
          subscriptionSelector={data => data.testSessionProgressResults}
          variables={{
            where: {}, orderBy: 'duration_DESC', skip: 0, first: 10
          }}
          testSession={testsession}>
          {({ testSessionProgress: testSessionResults }) => {
            if (!testSessionResults || !_.isArray(testSessionResults)) return null
            return (<Card>
              <CardHeader color="info">
                <Text header>Slowest Test Cases</Text>
                <Text subheader>Top 10 Test Cases with slowest execution time</Text>
              </CardHeader>
              <CardBody noPadding>
                <Table
                  name={`TestSessionDashboard_${testsession.id}_Slowest`}
                  disableHeader={true}
                  disableFooter={true}
                  tableHeaderColor="primary"
                  tableHead={[
                    '',
                    'Test Case',
                    'Duration'
                  ]}
                  pageLoading={false}
                  tableData={testSessionResults.map(r => [
                    () => r.success ? (<Text success><ShowIcon custom icon="success" /></Text>) : (<Text danger><ShowIcon custom icon="error" /></Text>),
                    () => <LinkButton gray onClick={() => goToTestCase(r.id, r.testcaseName)}>{r.testcaseName}</LinkButton>,
                    `${r.duration}ms`
                  ])
                  }
                />
              </CardBody>
            </Card>)
          }}
        </TestSessionProgress>
      </GridItem></>}
    </GridContainer>)
  }

  renderJobs(testsession) {
    const { classes, user } = this.props

    return (
      <TestSessionProgress testSession={testsession}>
        {({ testSessionProgress }) => {

          return (
            <GridContainer>
              <GridItem xs={10} center middle largePadding>
                <Text header primary bold>{testSessionProgress.jobs && testSessionProgress.jobs.length} JOBS</Text>
                <Text primary padding>|</Text>
                <Text header info bold>{testSessionProgress.jobs && testSessionProgress.jobs.filter(j => j.status !== 'READY' && j.status !== 'FAILED').length} RUNNING</Text>
                <Text primary padding>|</Text>
                <Text icon="square" danger inline contentCenter>&nbsp;</Text> <Text header inline bold>{testSessionProgress.jobs && testSessionProgress.jobs.filter(j => j.status === 'FAILED').length} FAILED</Text>
                <Text primary padding>|</Text>
                <Text icon="square" success inline contentCenter>&nbsp;</Text>  <Text header inline bold>{testSessionProgress.jobs && testSessionProgress.jobs.filter(j => j.status === 'READY').length} READY</Text>
              </GridItem>
              <GridItem xs={2} right noPadding>
                <Tooltip title={'Download logs for each jobs merged into one log file.'}>
                  <Button link secondary data-unique={`btnTestSessionDownloadAllJobLogs`} onClick={() => window.open(`${config.api.base}/alljoblogdata/${testsession.id}`)}>
                    <ShowIcon icon="download" /> Download Logs
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={12}>
                <List component="nav" key="jobs">
                  {testSessionProgress.jobs &&
                    testSessionProgress.jobs.map((job, jobIndex) => {
                      const onClick=() => {
                        if (hasPermission(user, 'TESTSESSIONS_REPORTS')) {
                          if (this.state.jobOpenId === job.id) {
                            this.setState({ jobOpenId: null })
                          } else {
                            this.setState({ jobOpenId: job.id })
                          }
                        }
                      }
                      return (
                        <React.Fragment key={job.id}>
                          <ExpansionPanel expanded={this.state.jobOpenId === job.id} >
                            <ExpansionPanelSummary
                              onClick={() => {
                                onClick()
                              }}
                              onKeyDown={(e) => {
                                if (e.keyCode === 32){
                                  onClick()
                                }
                              }}
                            >
                              <ListItem
                                data-unique={`liTestSessionJob${jobIndex}`}


                                className={classes.testcaselistitem}
                                key={job.id}
                              >
                                {job.status === 'READY' && <ListItemIcon className={classes.successText}><ShowIcon custom icon="success" /></ListItemIcon>}
                                {job.status === 'FAILED' && <ListItemIcon className={classes.dangerText}><ShowIcon custom icon="error" /></ListItemIcon>}
                                {job.status !== 'READY' && job.status !== 'FAILED' && <Text info><LoadingIndicator /></Text>}
                                {job.started && <ListItemText style={{ display: 'block', flex: 'unset' }}
                                  primary={<>Started: <DateFormat seconds>{job.started}</DateFormat></>}
                                  secondary={<>
                                    Progress: <Text icon="square" info={job.progressPercent !== 100} success={job.progressPercent === 100} inline contentCenter>&nbsp;</Text> <Text inline>{job.progressPercent}%</Text>
                                  </>}
                                  secondaryTypographyProps={{ component: 'span' }}
                                />}
                                {job.finished && <ListItemText style={{ display: 'block', flex: 'unset' }}
                                  primary={<>Finished: <DateFormat seconds>{job.finished}</DateFormat></>}
                                  secondary={<>
                                    Status: <Text icon="square" danger={job.status === 'FAILED'} success={job.status === 'READY'} info={job.status !== 'READY' && job.status !== 'FAILED'} inline contentCenter>&nbsp;</Text> <Text inline>
                                      {job.status || 'RUNNING'}
                                    </Text>
                                  </>}
                                  secondaryTypographyProps={{ component: 'span' }}
                                />}
                                {job.jobName && <ListItemText
                                  primary={job.jobName} {...((job.finished || job.started) ? { secondary: <>&nbsp;</>, secondaryTypographyProps: { component: 'span' } } : {})}
                                />}
                              </ListItem>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <GridContainer nounset>
                                <GridItem xs={12}>
                                  <Query
                                    query={TESTSESSIONJOB_LOGS_QUERY}
                                    variables={{ jobId: job.id, first: 26 }}
                                    fetchPolicy="network-only"
                                  >
                                    {({ loading, error, data, refetch }) => {
                                      if (error) {
                                        return <ErrorFormat err={error} />
                                      }
                                      if (loading) {
                                        return <LoadingIndicator large />
                                      }
                                      const rd = (data && data.testsessionjoblogs) || []
                                      return (
                                        <GridContainer>
                                          {job.err && (
                                            <GridItem xs={12}>
                                              <ErrorFormat err={job.err} suppress split />
                                            </GridItem>
                                          )}
                                          {rd && rd.length > 0 && <>
                                            <GridItem xs={12} right middle>
                                              <Button link secondary data-unique={`btnTestSessionRefreshJobLogs_${jobIndex}`} onClick={() => refetch()}>
                                                <ShowIcon icon="redo" /> Refresh
                                              </Button> <Text primary>|</Text> <Button link secondary data-unique={`btnTestSessionReadMoreJobLogs_${jobIndex}`} onClick={() => window.open(`${config.api.base}/joblog/${job.id}`, '_blank')}>
                                                <ShowIcon icon="eye" /> Full View
                                              </Button>
                                            </GridItem>
                                            <GridItem xs={12}>
                                              <AdvancedTable
                                                disableFooter
                                                disableHeader
                                                tableData={[...rd.slice(0, 25).map(l => {
                                                  const logLines = l.log ? l.log.split('\n') : []
                                                  return [
                                                    () =>
                                                      <Tooltip title={l.createdAt}>
                                                        <DateFormat seconds>
                                                          {l.createdAt}
                                                        </DateFormat>
                                                      </Tooltip>,
                                                    () => l.log &&
                                                      logLines.map((line, i) => (
                                                        <React.Fragment key={i}>
                                                          <Text pre linebreak>{line}</Text>
                                                          {i < logLines.length - 1 && (
                                                            <br />
                                                          )}
                                                        </React.Fragment>
                                                      ))
                                                  ]
                                                }), rd.length > 25 && [
                                                  () => '...',
                                                  () => null
                                                ]]} />
                                            </GridItem>
                                            {rd.length > 25 &&
                                              <GridItem xs={12} right middle>
                                                <Button link secondary data-unique={`btnTestSessionRefreshJobLogs2_${job.id}`} onClick={() => refetch()}>
                                                  <ShowIcon icon="redo" /> Refresh
                                                </Button> <Text primary>|</Text> <Button link secondary data-unique={`btnTestSessionReadMoreJobLogs2_${job.id}`} onClick={() => window.open(`${config.api.base}/joblog/${job.id}`, '_blank')}>
                                                  <ShowIcon icon="eye" /> Full View
                                                </Button>
                                              </GridItem>
                                            }
                                          </>}
                                        </GridContainer>
                                      )
                                    }}
                                  </Query>
                                </GridItem>
                              </GridContainer>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </React.Fragment>
                      )
                    })}
                </List>
              </GridItem>
            </GridContainer>
          )
        }}
      </TestSessionProgress>
    )
  }

  renderAnalytics(testsession) {
    const { setAlertSuccessMessage, setAlertErrorMessage, token, license, classes } = this.props

    return (
      <GridContainer>
        <GridItem md={12}>
          <GridContainer>
            {(!license.detailedReporting || !license.bugExport) && <GridItem xs={12}><FeatureUpgradeNavLink>Upgrade to get access to all exports</FeatureUpgradeNavLink></GridItem>}
            <GridItem md={12}>
              <GridContainer>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={() => {
                    downloadfileformpost(`${config.api.base}/transcript/${testsession.id}?REPORTER=json`, token).then(() => setAlertSuccessMessage('Full transcript exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3}>
                          <img src={imgJson} alt="JSON" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Download Full Transcript</Text>
                          <Text header>AS JSON</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex={-1} aria-label="Download Full Script Json" data-unique="btnTestSessionDownloadFullScriptJson" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={() => {
                    downloadfileformpost(`${config.api.base}/transcript/${testsession.id}?REPORTER=csv`, token).then(() => setAlertSuccessMessage('Full transcript exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer >
                        <GridItem md={3}>
                          <img src={imgCsv} alt="CSV" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Download Full Transcript</Text>
                          <Text header>AS CSV</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex="-1" aria-label="Download Full Script Csv" data-unique="btnTestSessionDownloadFullScriptCsv" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={() => {
                    downloadfileformpost(`${config.api.base}/build/${testsession.id}?REPORTER=json`, token).then(() => setAlertSuccessMessage('Test results exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3}>
                          <img src={imgJson} alt="JSON" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Test Results</Text>
                          <Text header>AS JSON</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button  tabIndex={-1} aria-label="Download Test Results Json" data-unique="btnTestSessionDownloadTestResultsJson" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={() => {
                    downloadfileformpost(`${config.api.base}/build/${testsession.id}?REPORTER=junit`, token).then(() => setAlertSuccessMessage('Test results exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3}>
                          <img src={imgXml} alt="XML" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Test Results</Text>
                          <Text header>AS XML</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex={-1} aria-label="Download Test Results Xml" data-unique="btnTestSessionDownloadTestResultsXml" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={() => {
                    downloadfileformpost(`${config.api.base}/build/${testsession.id}?REPORTER=csv`, token).then(() => setAlertSuccessMessage('Test results exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3}>
                          <img src={imgCsv} alt="CSV / EXCEL" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Test Results</Text>
                          <Text header>AS CSV</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex={-1} aria-label="Download Test Results Csv" data-unique="btnTestSessionDownloadTestResultsCsv" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={() => {
                    downloadfileformpost(`${config.api.base}/build/${testsession.id}?REPORTER=pdf`, token).then(() => setAlertSuccessMessage('Test results exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3}>
                          <img src={imgPdf} alt="PDF" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Test Results</Text>
                          <Text header>AS PDF</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex={-1} aria-label="Download Test Result Pdf" data-unique="btnTestSessionDownloadTestResultPdf" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={() => {
                    downloadfileformpost(`${config.api.base}/build/${testsession.id}?REPORTER=jiracsv`, token).then(() => setAlertSuccessMessage('Failed test results exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3}>
                          <img src={imgCsv} alt="CSV" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Failed Test Results</Text>
                          <Text header>AS CSV (Jira)</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex={-1} aria-label="Export Failed Test Results" data-unique="btnTestSessionExportFailedJira" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  }


  getRootPath = (mode) => {
    return getRootPath(this.props.location, mode === 'testsession' ? 6 : mode === 'testproject' ? 4 : null)
  }

  render() {
    const { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry, history, user, license } = this.props
    const { match, testsession, loading } = this.props

    const testSessionId = match.params.id
    const connectorTabs = ConnectorsWithUI[testsession.chatbot?.containermode]?.testSessionTabs?.(testsession) || []

    return (
      <CustomTabs
        name={`tabTestSession_${testSessionId}`}
        headerColor="info"
        plainTabs
        tabs={[
          {
            tabName: 'Overview',
            tabIcon: <ShowIcon icon="tachometer-alt" />,
            tabContent: this.renderDashboard(testsession, loading),
            locationPrefix: `${this.getRootPath('testsession')}/dashboard`,
            dataUnique: 'btnTestSessionNavigationDashboard'
          },
          this.hasReadPermission() && {
            tabName: 'Test Cases',
            tabIcon: <ShowIcon icon="list-ul" />,
            tabContent: <TestSessionTestCases testSession={testsession} onOpenJobId={(id) => this.setState({ jobOpenId: id })} {...this.state.testcaseProps || {}} />,
            locationPrefix: `${this.getRootPath('testsession')}/testcases`,
            dataUnique: 'btnTestSessionNavigationTestCases'
          },
          ...(this.hasReadPermission() ? connectorTabs.filter(entry => entry.available).map(entry => ({
            tabName: entry.label,
            tabIcon: entry.icon,
            tabContent: entry.render(),
            locationPrefix: `${this.getRootPath('testsession')}/${entry.name}`,
            dataUnique: `btnTestSessionNavigation${entry.name}`
          })) : []),
          this.hasReadPermission() && {
            tabName: 'Test Session Jobs',
            tabIcon: <ShowIcon icon="cogs" />,
            tabContent: this.renderJobs(testsession),
            locationPrefix: `${this.getRootPath('testsession')}/jobs`,
            dataUnique: 'btnTestSessionNavigationTestSessionJobs'
          },
          this.hasReadPermission() && {
            tabName: 'Downloads',
            tabIcon: <ShowIcon icon="cloud-download-alt" />,
            disabled: !hasPermission(user, 'TESTSESSIONS_REPORTS'),
            tabContent: this.renderAnalytics(testsession),
            locationPrefix: `${this.getRootPath('testsession')}/downloads`,
            dataUnique: 'btnTestSessionNavigationDownloads'
          },
          this.hasWritePermission() && hasPermission(user, 'TESTSESSIONS_DELETE') && {
            tabName: 'Danger Zone',
            tabRight: true,
            tabIcon: <ShowIcon icon="exclamation-triangle" />,
            locationPrefix: `${this.getRootPath('testsession')}/danger`,
            dataUnique: 'btnTestSessionNavigationDangerZone',
            tabContent: (
              <GridContainer key="danger">
                {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_DELETE') &&
                  <React.Fragment>
                    <GridItem md={8} lg={4}>
                      <ListItem>
                        <Text lg danger padding><ShowIcon icon="trash" /></Text>

                        <GridContainer nounset>
                          <GridItem md={12}><Text bold>Delete Test Session</Text></GridItem>
                          <GridItem md={12}><Text>This removes the Test Session and its results</Text></GridItem>
                        </GridContainer>
                        <Mutation
                          mutation={DELETE_TESTSESSION}
                          onCompleted={data => {
                            removeRecentListEntry({
                              url: `${this.getRootPath('testsession')}`
                            })
                            setAlertSuccessMessage('Test Session deleted')
                            history.push(`${this.getRootPath('testproject')}`)
                          }}
                          onError={error => {
                            setAlertErrorMessage('Test session deletion failed', error)
                          }}
                          update={DeleteTestSessionListsFromCache}
                        >
                          {(
                            deleteTestSession,
                            { loading, error },
                          ) => (
                            <ConfirmationButton
                              confirmationText={`When deleting this Test Session, all it's test results are lost. You have to start another Test Session to get current results. If the session is running, it will be cancelled first. Are you sure you want to delete it ?`}
                              requireCheck={true}
                              danger
                              small
                              minWidth
                              onClick={() => {
                                deleteTestSession({
                                  variables: { id: testsession.id },
                                })
                              }}
                              data-unique="btnTestSessionDelete"
                            >

                              Delete
                            </ConfirmationButton>
                          )}
                        </Mutation>
                      </ListItem>
                    </GridItem>
                    <GridItem md={8}></GridItem>
                  </React.Fragment>
                }
                {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_DELETE') && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                  <React.Fragment>
                    <GridItem md={8} lg={4}><Divider dense /></GridItem>
                    <GridItem md={12} lg={8}></GridItem>
                  </React.Fragment>
                }
                {hasPermission(user, 'TESTSESSIONS_CREATE') &&
                  <React.Fragment>
                    <GridItem md={8} lg={4}>
                      <ListItem>
                        <Text lg danger padding><ShowIcon icon="power-off" /></Text>
                        <GridContainer nounset>
                          <GridItem md={12}><Text bold>Send Cancellation Request</Text></GridItem>
                          <GridItem md={12}><Text>Send cancellation request to Test Session</Text></GridItem>
                        </GridContainer>
                        <Mutation
                          mutation={CANCEL_TESTSESSION}
                          onCompleted={data => {
                            setAlertSuccessMessage('Sent cancellation request to Test Session')
                            this.setState({ testSessionProgressKey: this.state.testSessionProgressKey + 1 })
                          }}
                          onError={error => {
                            setAlertErrorMessage('Sending cancellation request to Test Session failed', error)
                          }}
                        >
                          {(
                            cancelTestSession,
                            { loading, error },
                          ) => (
                            <TestSessionProgress testSession={testsession}>
                              {({ testSessionProgress }) => (
                                <ConfirmationButton
                                  confirmationText={`When cancelling a Test Session, all background processing will be stopped and the test results are not complete. Are you sure you want to cancel it ?`}
                                  requireCheck={true}
                                  danger
                                  small
                                  minWidth
                                  disabled={testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED'}
                                  onClick={() => {
                                    cancelTestSession({
                                      variables: { id: testsession.id },
                                    })
                                  }}
                                  data-unique="btnTestSessionCancel"
                                >
                                  Send
                                </ConfirmationButton>
                              )}
                            </TestSessionProgress>
                          )}
                        </Mutation>
                      </ListItem>
                    </GridItem>
                    <GridItem xs={8}></GridItem>
                  </React.Fragment>
                }
                {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                  <React.Fragment>
                    <GridItem xs={4}><Divider dense /></GridItem>
                    <GridItem xs={8}></GridItem>
                  </React.Fragment>
                }
                {hasPermission(user, 'TESTSESSIONS_CREATE') &&
                  <React.Fragment>
                    <GridItem xs={4}>
                      <ListItem>
                        <Text lg danger padding><ShowIcon icon="redo" /></Text>
                        <GridContainer nounset>
                          <GridItem xs={12}><Text bold>Restart Test Session</Text></GridItem>
                          <GridItem xs={12}><Text>Remove all test results and restart the Test Session from the beginning</Text></GridItem>
                        </GridContainer>
                        <Mutation
                          mutation={RETRY_TESTSESSION}
                          onCompleted={data => {
                            setAlertSuccessMessage('Test Session is restarted')
                            this.setState({ testSessionProgressKey: this.state.testSessionProgressKey + 1 })
                          }}
                          onError={error => {
                            setAlertErrorMessage('Restarting Test Session failed', error)
                          }}
                        >
                          {(retryTestSession, { loading, error }) => (
                            <TestSessionProgress testSession={testsession}>
                              {({ testSessionProgress }) => (
                                <ConfirmationButton
                                  confirmationText={`When restarting a Test Session, already existing test results are deleted and repeated. Are you sure you want to restart ?`}
                                  requireCheck={true}
                                  danger
                                  small
                                  minWidth
                                  disabled={!(testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')}
                                  onClick={() => {
                                    retryTestSession({
                                      variables: { id: testsession.id, allTestCases: true },
                                    })
                                  }}
                                  data-unique="btnTestSessionRetryAll"
                                >
                                  Restart
                                </ConfirmationButton>
                              )}
                            </TestSessionProgress>
                          )}
                        </Mutation>
                      </ListItem>
                    </GridItem>
                  </React.Fragment>
                }
              </GridContainer>
            ),
          }
        ].filter(t => t)}
      />
    )
  }
}

const TestSessionWithGraphql = compose(
  graphql(START_TESTPROJECT, {
    props: ({ mutate }) => ({
      mutateStartTestProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.startTestProject.id
        props.setAlertSuccessMessage('Test session started ...')
        props.history.push(`${getRootPath(props.location, 4)}/results/${testSessionId}`)

      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestProjectQueriesOnNewTestSession(data.startTestProject.testProject.id),
        ...RefetchTestSessionQueries(data.startTestProject.id)
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
  graphql(START_FACTCHECKERPROJECT, {
    props: ({ mutate }) => ({
      mutateStartFactCheckerProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.startFactCheckerProject.id
        props.setAlertSuccessMessage('Test session started ...')
        props.history.push('/factcheck/projects/view/' + data.startFactCheckerProject.id + '/results/' + testSessionId)
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestProjectQueriesOnNewTestSession(data.startFactCheckerProject.factCheckerSession.project.id),
        ...RefetchTestSessionQueries(data.startFactCheckerProject.id)
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
  graphql(RETRY_TESTSESSION, {
    props: ({ mutate }) => ({
      mutateRetryTestSession: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('Test session restarted ...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session restart failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestSessionQueries(data.retryTestSession.id)
      ]
    })
  }))(TestSession)

const TestSessionComponent = connect(
  state => ({ token: state.token.token, user: state.token.user, license: state.settings.license, features: state.settings.features }),
  { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
)(withStyles(testsessionsStyle, { withTheme: true })(TestSessionWithGraphql))


export default connect(state => ({ user: state.token.user }))(function ({ match, user, ...rest }) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        {match.params && match.params.id && (
          <Query query={TESTSESSION_QUERY} variables={{ id: match.params.id }}>
            {(queryResult) => <QueryStatus {...queryResult} query="testsession" card loadable>{({ testsession, loading }) => {
              if (!testsession) testsession = { id: match.params.id, results: [], jobs: [], testProject: { nlpAnalytics: false } }

              return <TestSessionComponent
                match={match}
                testsession={testsession}
                loading={loading}
                hasReadPermissions={hasPermission(user, 'TESTSESSIONS_SELECT') && canReadNamespace(user, user.namespacePermissions, testsession.namespace)}
                hasWritePermissions={hasAnyPermission(user, ['TESTSESSIONS_CREATE', 'TESTSESSIONS_UPDATE']) && canWriteNamespace(user, user.namespacePermissions, testsession.namespace)}
                {...rest} />
            }}</QueryStatus>}
          </Query>
        )}
      </GridItem>
    </GridContainer>
  )
})
